import React, { useEffect, useState } from 'react';
import { Divider, Empty } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Typography from 'components/Typography';
import Loader from 'components/Loader';
import analytics from 'utils/analytics';
import routes from 'routes';

import BusinessOverviewCard from './components/BusinessOverviewCard';
import businessApi from '../../../network/businessApi';
import { convertToSlug } from '../../../utils/strings';
import AppBanner from '../../../components/AppBanner';

const DAY_OF_WEEK_MAP = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};
const DATE_FORMAT = 'YYYY-MM-DD'; // 2021-01-08
const SCROLL_POSITION_KEY = 'scroll-position';

const ShiftsListPage = () => {
  const history = useHistory();
  const location = useLocation();

  // const [filteredDate, setFilteredDate] = useState();
  // const [filteredRoleIds, setFilteredRoleIds] = useState();
  // const [shifts, setShifts] = useState();

  const [businesses, setBusinesses] = useState();

  // Hacky scroll position restoration
  // It also restores scroll if the user loads /shifts/ page the first time, but have position saved previously
  const saveScrollPosition = () => {
    const scrollPosition = {
      path: location.pathname,
      scrollY: window.scrollY,
    };
    window.localStorage.setItem(
      SCROLL_POSITION_KEY,
      JSON.stringify(scrollPosition),
    );
  };

  const restoreScroll = () => {
    // If localStorage has scroll position + user hit back button, restore scroll position
    // Not sure if this is the right way to detect
    const userHitBack = location.action === undefined;
    const scrollPositionString = window.localStorage.getItem(
      SCROLL_POSITION_KEY,
    );
    if (userHitBack && scrollPositionString) {
      const scrollPosition = JSON.parse(scrollPositionString);
      if (scrollPosition.path === location.pathname) {
        window.scrollTo(0, scrollPosition.scrollY);
        // Remove it so that we don't restore scroll position if user closes and reopens window
        window.localStorage.removeItem(SCROLL_POSITION_KEY);
      }
    }
  };

  useEffect(() => {
    if (businesses) {
      restoreScroll();
    }
  }, [businesses]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await businessApi.listWithUpcomingShifts({
        page_size: 50,
      });
      setBusinesses(response.data.results);
    };

    fetchData();
  }, []);

  useEffect(() => {
    analytics.page('Shifts');
  }, []);

  const initialized = !!businesses;

  if (!initialized) {
    return <Loader loadingText="Loading businesses on Staffie..." />;
  }

  return (
    <div>
      <Helmet>
        <title>Staffie - Shifts</title>
      </Helmet>

      <section style={{ marginBottom: 32 }}>
        <AppBanner />
      </section>

      <section style={{ marginBottom: 32 }}>
        <Typography.H3>Find shifts on Staffie</Typography.H3>
      </section>

      {/* List of businesses */}
      <section>
        {businesses.length === 0 ? (
          <div style={{ margin: '120px 0px' }}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No more shifts available. Come back tomorrow to check again"
            />
          </div>
        ) : (
          <>
            {businesses.map((business) => {
              const url = routes.shiftBusiness
                .replace(':id', business.id)
                .replace(':safename', convertToSlug(business.name));

              return (
                <div key={business.slug}>
                  <Link to={url} target="_blank" onClick={saveScrollPosition}>
                    <BusinessOverviewCard business={business} />
                  </Link>
                  <Divider style={{ marginBottom: 48 }} />
                </div>
              );
            })}
          </>
        )}
      </section>
      <section style={{ marginBottom: 40 }}>
        <AppBanner
          title="View more shifts on the app"
          body="Staffie is now on Android and iOS. Install the app to view all available shifts!"
          buttonText="Get the app"
        />
      </section>
    </div>
  );
};
export default ShiftsListPage;
