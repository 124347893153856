import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Divider, Input, message, Modal, Space } from 'antd';

import Typography from 'components/Typography';

import analytics from 'utils/analytics';
import routes from 'routes';
import colors from 'colors';
import bookingsApi from '../../../../../../network/bookingsApi';

const codeOfConductTerms = [
  {
    title: 'You agree to:',
    terms: [
      'Show up for the shifts you have booked.',
      'Be punctual and professional.',
      'Be kind and thoughtful.',
      'Do an awesome job.',
    ],
  },
  {
    title: 'Cancellations:',
    terms: [
      'If you cancel within 24 hours of the shift, you will be de-prioritised for future shifts.',
      'If you cancel within 48 hours of the shift with acceptable reasons, then there’s no penalty.',
    ],
  },
  {
    title: 'If you don’t show up without notice and reason:',
    terms: [
      'Your account will be banned.',
      'Any pay that hasn’t been paid out will be withheld.',
    ],
  },
];

const CodeOfConductModal = ({ shifts, onSuccess, onCancel }) => {
  const [proName, setProName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    analytics.track('Viewed Code of Conduct Form');
  }, []);

  const submitForm = async () => {
    setLoading(true);
    analytics.track('Submitted Code of Conduct Form');
    try {
      analytics.track('Submitted Shift Bookings', { shifts });
      await bookingsApi.bulkCreate(shifts);
      analytics.track('Shift Bookings Successful', { shifts });

      ReactDOM.unstable_batchedUpdates(() => {
        setProName('');
        setLoading(false);
      });
      message.success(
        "We've received your booking request. We will verify your shifts and notify you when it's confirmed.",
        5,
      );
      onSuccess();
    } catch (e) {
      analytics.track('Shift Bookings Failed');
    }
  };

  return (
    <Modal
      centered
      visible
      bodyStyle={{ maxHeight: 500, overflow: 'auto' }}
      title={
        <Typography.Body1SharpGrotesk style={{ fontWeight: 'bold' }}>
          Pros - Code of Conduct
        </Typography.Body1SharpGrotesk>
      }
      footer={
        <Button
          block
          type="primary"
          key="submit"
          size="large"
          loading={loading}
          onClick={(values) => {
            analytics.track('Clicked Agree Code of Conduct');
            submitForm(values);
          }}
          disabled={!proName}
        >
          I agree
        </Button>
      }
      onCancel={onCancel}
    >
      <Space direction="vertical" size={16}>
        <Typography.Body2>
          At Staffie, we want to help you work when you want, and earn what you
          need. However, with great flexibility comes great responsibility.
        </Typography.Body2>
        <Space direction="vertical" size={0}>
          {codeOfConductTerms.map(({ title, terms }) => (
            <section key={title}>
              {title}
              <Typography.Body2>
                <ul>
                  {terms.map((term) => (
                    <li key={term}>{term}</li>
                  ))}
                </ul>
              </Typography.Body2>
            </section>
          ))}
          <a
            href={routes.external.faq}
            target="_blank"
            rel="noreferrer"
            style={{ color: colors.green }}
            onClick={() =>
              analytics.track('Clicked FAQ', {
                context: 'Code of Conduct',
              })
            }
          >
            Read more on our FAQ ↗
          </a>
        </Space>
        <Divider />

        <Space direction="vertical" size={16}>
          <section>
            Agreement
            <Typography.Body2>
              I hereby acknowledge that I have read and understand, and agree to
              Staffie’s Code of Conduct.
            </Typography.Body2>
          </section>

          <section>
            Enter your full name
            <Input
              style={{ marginTop: 4 }}
              size="large"
              width="100%"
              placeholder="Hersha Patel"
              onChange={(e) => setProName(e.target.value)}
              value={proName}
            />
          </section>
        </Space>
      </Space>
    </Modal>
  );
};

export default CodeOfConductModal;
