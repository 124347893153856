import axios from 'axios';
import config from 'config';
import localStorageKeys from 'constants/localStorageKeys';

const UNAUTHORIZED_STATUS_CODE = 401;

const baseApi = axios.create({
  baseURL: config.API_ROOT,
});

// Whenever a request is made, we attach our token in header.
baseApi.interceptors.request.use((axiosRequestConfig) => {
  const token = localStorage.getItem(localStorageKeys.AUTH_TOKEN);
  if (token) {
    axiosRequestConfig.headers.Authorization = `Token ${token}`;
  }
  return axiosRequestConfig;
});

// Whenever a response is received
baseApi.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response.status === UNAUTHORIZED_STATUS_CODE) {
      localStorage.removeItem(localStorageKeys.AUTH_TOKEN);
    }
    return Promise.reject(error);
  },
);

export default baseApi;
