const workEligibilityOptions = [
  {
    value: 'Citizen or Permanent Resident',
    label: 'Singapore Citizen or PR',
  },
  { value: 'Employment Pass', label: 'Employment Pass' },
  { value: 'Temporary Employment Pass', label: 'Temporary Employment Pass' },
  { value: 'Professional Visit Pass', label: 'Professional Visit Pass' },
  { value: 'Student Pass', label: 'Student Pass (Foreigner)' },
  { value: 'S Pass', label: 'S Pass' },
  { value: 'Work Permit', label: 'Work Permit' },
  { value: "Dependant's Pass / LTVP", label: "Dependant's Pass / LTVP" },
  { value: 'Other', label: 'Other' },
];

export default workEligibilityOptions;
