import React, { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Form, Button, Input, Select, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import ProContext from 'contexts/ProContext';
import colors from 'colors';
import routes from 'routes';
import analytics from 'utils/analytics';
import prosApi from 'network/prosApi';
import { normalizePhoneNumber } from 'utils/strings';
import countryConfigs from 'constants/countryConfigs';

import Loader from 'components/Loader';
import Typography from 'components/Typography';
import OtpModal from 'components/OtpModal';

const LoginPage = () => {
  const { setProfile } = useContext(ProContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const history = useHistory();

  const onOtpSuccess = async () => {
    setLoading(true);

    try {
      const proResponse = await prosApi.getMyself();
      analytics.identifyPro(proResponse.data);
      analytics.track('Login Successful');

      ReactDOM.unstable_batchedUpdates(() => {
        setShowOtpModal(false);
        setProfile(proResponse.data);
        setLoading(false);
      });
      history.replace(routes.shiftsHome);
    } catch (error) {
      analytics.track('Login Failed');
      message.error('Sorry, something went wrong. Unable to login');
    }
  };

  if (loading) {
    return <Loader />;
  }

  const mobileNumber =
    form.getFieldValue('phonePrefix') + form.getFieldValue('phoneNumber');
  return (
    <>
      <Typography.H3 style={{ marginTop: 16 }}>Login</Typography.H3>

      <Form
        hideRequiredMark
        form={form}
        layout="vertical"
        size="large"
        initialValues={{
          phonePrefix: countryConfigs.SG.phonePrefix,
        }}
        validateTrigger={['onSubmit', 'onBlur']}
        onFinish={() => {
          analytics.track('Submitted Login Form');
          setShowOtpModal(true);
        }}
        style={{ marginBottom: 16 }}
      >
        <Form.Item label="Phone Number" style={{ marginBottom: 0 }}>
          <div style={{ display: 'flex' }}>
            <Form.Item name="phonePrefix">
              <Select>
                <Select.Option value={countryConfigs.SG.phonePrefix}>
                  🇸🇬 (SG) +65
                </Select.Option>
                <Select.Option value={countryConfigs.MY.phonePrefix}>
                  🇲🇾 (MY) +60
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              rules={[{ required: true, message: 'This field is required' }]}
              normalize={(value) => {
                return normalizePhoneNumber(value);
              }}
              hasFeedback
              style={{ marginLeft: 4, flex: 1 }}
            >
              <Input
                placeholder="87778777"
                minLength={countryConfigs.SG.mobileNumberLength}
                maxLength={countryConfigs.SG.mobileNumberLength}
              />
            </Form.Item>
          </div>
        </Form.Item>

        <Typography.Body2Sans style={{ color: colors.grey, marginBottom: 8 }}>
          An OTP will be sent to your phone number
        </Typography.Body2Sans>
        <Button
          block
          type="primary"
          htmlType="submit"
          onClick={() =>
            analytics.track('Clicked Login', {
              context: 'Login',
            })
          }
        >
          Login
        </Button>
      </Form>

      <Typography.Caption>
        Don't have an account?
        <Button
          type="text"
          style={{ marginLeft: 4, padding: 0, fontSize: 'inherit' }}
          onClick={() => {
            analytics.track('Clicked Sign Up', {
              context: 'Login',
            });
          }}
        >
          <Link
            to={routes.signup}
            style={{
              textDecoration: 'underline',
              fontWeight: 'bold',
              fontSize: 'inherit',
            }}
          >
            Sign up
          </Link>
        </Button>
      </Typography.Caption>

      {/* Modals */}
      {showOtpModal && (
        <OtpModal
          mobileNumber={mobileNumber}
          onCancel={() => {
            analytics.track('Closed OTP Modal', {
              context: 'Login',
            });
            setShowOtpModal(false);
          }}
          onSuccess={onOtpSuccess}
          successMessageText=""
        />
      )}
    </>
  );
};

export default LoginPage;
