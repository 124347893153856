import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, message, Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import colors from 'colors';
import localStorageKeys from 'constants/localStorageKeys';
import Typography from 'components/Typography';
import Loader from 'components/Loader';
import analytics from 'utils/analytics';
import usersApi from '../network/usersApi';

const ACCOUNT_DOES_NOT_EXIST = 'account_does_not_exist';
const ACCOUNT_ALREADY_EXISTS = 'account_already_exists';

const OtpModal = ({
  signup = false,
  mobileNumber,
  onCancel,
  onSuccess,
  onAccountExists,
}) => {
  const [mobileExists, setMobileExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [otpFailed, setOtpFailed] = useState(false);
  const otpRef = useRef();
  const [form] = Form.useForm();

  const createOtp = async () => {
    setLoading(true);
    try {
      await usersApi.createOtp(mobileNumber, signup);
    } catch (error) {
      if (error?.response?.data?.code === ACCOUNT_DOES_NOT_EXIST) {
        message.error(`Account does not exist`);
        analytics.track('OTP Failed');
        onCancel();
        return;
      }
      if (error?.response?.data?.code === ACCOUNT_ALREADY_EXISTS) {
        message.error(`Account already exists. Please log in.`);
        analytics.track('OTP Failed');
        onAccountExists();
        return;
      }
      message.info(
        'Sorry! Unable to create OTP, try resending. Contact us if the problem continues',
      );
      setOtpFailed(true);
    }
    setMobileExists(true);
    setLoading(false);
  };

  useEffect(() => {
    analytics.track('Viewed OTP Form');
    createOtp();
  }, []);

  useEffect(() => {
    const focusOnOtpInput = () => {
      if (!loading) {
        otpRef?.current?.focus({ cursor: 'start' });
      }
    };

    focusOnOtpInput();
  }, [loading]);

  const verifyOtp = async (otp) => {
    setSubmitting(true);
    analytics.track('Submitted OTP Form');
    try {
      const response = await usersApi.checkOtp(mobileNumber, otp);
      analytics.track('OTP Successful');
      if (response.data.auth_token) {
        localStorage.setItem(
          localStorageKeys.AUTH_TOKEN,
          response.data.auth_token,
        );
      }
      await onSuccess();
    } catch (error) {
      analytics.track('OTP Failed');
      setOtpFailed(true);
    }
    setSubmitting(false);
  };

  if (!mobileExists) {
    return <></>;
  }

  return (
    <Modal
      centered
      visible
      onCancel={onCancel}
      bodyStyle={{ maxHeight: 500, overflow: 'auto' }}
      title={
        <Typography.Body1SharpGrotesk style={{ fontWeight: 'bold' }}>
          Confirm your number
        </Typography.Body1SharpGrotesk>
      }
      footer={null}
    >
      {loading ? (
        <Loader loadingText="Sending OTP..." />
      ) : (
        <>
          <Typography.Body2 style={{ marginBottom: 32 }}>
            {`Enter the code we've sent via SMS to ${mobileNumber}`}
          </Typography.Body2>

          <Form
            form={form}
            initialValues={{ otp: '' }}
            validateTrigger={['onSubmit', 'onBlur']}
            onFinish={(values) => {
              return verifyOtp(values.otp);
            }}
            layout="vertical"
          >
            <Form.Item
              name="otp"
              label="6-digit code"
              rules={[{ len: 6, message: 'Please input a valid code' }]}
            >
              <Input
                ref={otpRef}
                size="large"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                autoComplete="one-time-code"
              />
            </Form.Item>
            <Button
              block
              loading={submitting}
              type="primary"
              htmlType="submit"
              size="large"
              onClick={() => {
                analytics.track('Clicked Continue', {
                  context: 'OTP',
                });
              }}
            >
              Continue
            </Button>
          </Form>

          <Typography.Caption style={{ marginTop: 16, color: colors.grey }}>
            Haven't received a code?
            <Button
              type="text"
              style={{
                marginLeft: 4,
                padding: 0,
                color: 'inherit',
                fontSize: 'inherit',
              }}
              onClick={() => {
                analytics.track('Clicked Resend OTP');
                setOtpFailed(false);
                createOtp();
              }}
            >
              <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                Resend SMS
              </span>
            </Button>
          </Typography.Caption>

          {otpFailed && (
            <section style={{ marginTop: 8 }}>
              <Typography.Caption
                style={{ color: colors.red, marginBottom: 16 }}
              >
                <InfoCircleOutlined /> Login failed because confirmation code
                was incorrect or expired.
              </Typography.Caption>
            </section>
          )}
        </>
      )}
    </Modal>
  );
};

export default OtpModal;
