import React from 'react';
import { Button, Form, Input } from 'antd';

const RequirementsForm = ({ requirements, onChange, onBack, onNext }) => {
  const { attire, duties, notes } = requirements;

  return (
    <div>
      <Form.Item label="Attire">
        <Input
          value={attire}
          onChange={(e) =>
            onChange({ ...requirements, attire: e.target.value })
          }
        />
      </Form.Item>
      <Form.Item label="Duties (Optional)">
        <Input
          value={duties}
          onChange={(e) =>
            onChange({ ...requirements, duties: e.target.value })
          }
        />
      </Form.Item>
      <Form.Item label="Notes (Optional)">
        <Input.TextArea
          value={notes}
          onChange={(e) => onChange({ ...requirements, notes: e.target.value })}
        />
      </Form.Item>
      <Button type="secondary" onClick={onBack}>
        Back
      </Button>
      <Button type="primary" onClick={onNext}>
        Next
      </Button>
    </div>
  );
};

export default RequirementsForm;
