import { Button, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '../../../components/Typography';
import countryConfigs from '../../../constants/countryConfigs';
import { normalizePhoneNumber } from '../../../utils/strings';
import analytics from '../../../utils/analytics';
import routes from '../../../routes';
import colors from '../../../colors';
import OtpModal from '../../../components/OtpModal';

const MobileForm = ({ mobile: initialMobile, onSuccess }) => {
  const [form] = Form.useForm();
  const [mobile, setMobile] = useState(initialMobile);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const handleFinish = (values) => {
    analytics.track('Mobile Form Submitted');
    setMobile(values.phonePrefix + values.phoneNumber);
    setShowOtpModal(true);
  };

  const handleOtpSuccess = () => {
    analytics.track('Mobile Form Completed');
    setShowOtpModal(false);
    onSuccess(mobile);
  };

  return (
    <div>
      <div style={{ marginBottom: 40 }}>
        <Typography.H4 style={{ marginBottom: 0 }}>
          Sign up to Staffie
        </Typography.H4>
        <Typography.Body2>
          You're on your way to working flexibly and getting paid weekly.
        </Typography.Body2>
      </div>
      <Form
        form={form}
        layout="vertical"
        size="large"
        requiredMark={false}
        initialValues={{
          phonePrefix: countryConfigs.SG.phonePrefix,
          phoneNumber: initialMobile
            ? normalizePhoneNumber(initialMobile)
            : undefined,
        }}
        onFinish={handleFinish}
      >
        <Form.Item label="Mobile number">
          <div style={{ display: 'flex' }}>
            <Form.Item name="phonePrefix">
              <Select>
                <Select.Option value={countryConfigs.SG.phonePrefix}>
                  🇸🇬 (SG) +65
                </Select.Option>
                <Select.Option value={countryConfigs.MY.phonePrefix}>
                  🇲🇾 (MY) +60
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              rules={[{ required: true, message: 'This field is required' }]}
              normalize={(value) => {
                return normalizePhoneNumber(value);
              }}
              hasFeedback
              style={{ marginLeft: 4, flex: 1 }}
            >
              <Input
                placeholder="87778777"
                minLength={countryConfigs.SG.mobileNumberLength}
                maxLength={countryConfigs.SG.mobileNumberLength}
              />
            </Form.Item>
          </div>
        </Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          onClick={() => {
            analytics.track('Clicked Continue', { context: 'Mobile Form' });
          }}
        >
          Continue
        </Button>
      </Form>
      <Typography.Caption
        style={{ marginTop: 72, marginBottom: 16, color: colors.grey }}
      >
        Already have an account?
        <Button
          type="text"
          style={{
            marginLeft: 4,
            padding: 0,
            fontSize: 'inherit',
            color: 'inherit',
          }}
          onClick={() => {
            analytics.track('Clicked Log in', {
              context: 'Mobile Form',
            });
          }}
        >
          <Link
            to={routes.login}
            style={{
              textDecoration: 'underline',
              fontWeight: 'bold',
              fontSize: 'inherit',
            }}
          >
            Log in
          </Link>
        </Button>
      </Typography.Caption>
      {showOtpModal && (
        <OtpModal
          signup
          mobileNumber={mobile}
          onCancel={() => {
            analytics.track('Closed OTP Modal', {
              context: 'Signup',
            });
            setShowOtpModal(false);
          }}
          onSuccess={handleOtpSuccess}
          onAccountExists={() => {
            setShowOtpModal(false);
            form.setFields([
              {
                name: 'phoneNumber',
                errors: ['Account already exists. Please log in.'],
              },
            ]);
          }}
        />
      )}
    </div>
  );
};

export default MobileForm;
