// These are APIs that will only be used by Lerk's admin page

import baseApi from './baseApi';

const getManagers = (params) => {
  return baseApi.get('/managers/', { params });
};

const getRoles = () => {
  return baseApi.get('/roles/');
};

const bulkCreateShifts = (data) => {
  return baseApi.post('/shifts/bulk-create/', data);
};

const fetchShifts = (params) => {
  return baseApi.get('/shifts/', { params });
};

export default {
  getManagers,
  getRoles,
  fetchShifts,
  bulkCreateShifts,
};
