export const environments = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

export const getEnvironment = () => {
  const hostname = window && window.location && window.location.hostname;
  if (hostname === 'pro.staffie.app') {
    return environments.PRODUCTION;
  }
  if (hostname === 'pro.staging.staffie.app') {
    return environments.STAGING;
  }
  return environments.STAGING;
  // return environments.DEVELOPMENT;
};

const config = {
  DEVELOPMENT: {
    API_ROOT: 'http://localhost/v1/',
  },
  STAGING: {
    API_ROOT: 'https://api.staging.staffie.app/v1/',
  },
  PRODUCTION: {
    API_ROOT: 'https://api.staffie.app/v1/',
  },
};

const environment = getEnvironment();
// eslint-disable-next-line import/no-mutable-exports
let exportedConfig;
if (environment === environments.PRODUCTION) {
  exportedConfig = config.PRODUCTION;
} else if (environment === environments.STAGING) {
  exportedConfig = config.STAGING;
} else {
  exportedConfig = config.DEVELOPMENT;
}

export default exportedConfig;
