import React, { useEffect, useState } from 'react';
import { message, Select } from 'antd';
import lodash from 'lodash';
import RoleForm from './RoleForm';
import ShiftsScheduleForm from './ShiftsScheduleForm';
import ShiftsReview from './ShiftsReview';
import adminApi from '../../network/adminApi';
import RequirementsForm from './RequirementsForm';

const STEP_ROLE = 1;
const STEP_REQUIREMENTS = 2;
const STEP_SHIFTS = 3;
const STEP_REVIEW = 4;

const ShiftsPostingForm = ({ manager }) => {
  const [step, setStep] = useState(STEP_ROLE);
  const [businessId, setBusinessId] = useState(manager.businesses[0]?.id);
  const [role, setRole] = useState();
  const [shifts, setShifts] = useState([]);
  const [pastShifts, setPastShifts] = useState();
  const [rolePastShifts, setRolePastShifts] = useState();
  const [requirements, setRequirements] = useState({});

  const fetchPastShifts = async () => {
    setPastShifts(undefined);
    const response = await adminApi.fetchShifts({
      business_id: businessId,
      cancelled: false,
    });
    setPastShifts(
      lodash.orderBy(response.data.results, 'start_time', ['desc']),
    );
  };

  const resetState = () => {
    setStep(STEP_ROLE);
    setRole(null);
    setShifts([]);
    setRequirements({});
  };

  useEffect(() => {
    setBusinessId(manager.businesses[0]?.id);
  }, [manager]);

  useEffect(() => {
    if (!businessId) return;

    // Reset state
    resetState();
    fetchPastShifts();
  }, [businessId]);

  useEffect(() => {
    if (!pastShifts || !role) return;

    setRolePastShifts(pastShifts.filter((s) => s.role.id === role.id));
  }, [pastShifts, role]);

  const handleRoleChange = (newRole) => {
    setRole(newRole);
    if (!pastShifts || !newRole) return;

    // Set duties based on past shifts
    // Find the latest shift with this role
    const latestShiftForRole = pastShifts.filter(
      (s) => s.role.id === newRole.id,
    )[0];
    if (latestShiftForRole) {
      setRequirements({
        duties: latestShiftForRole.duties,
        attire: latestShiftForRole.attire,
        notes: latestShiftForRole.notes,
      });
    }
  };

  const handleSubmit = async () => {
    const payload = shifts.map((shift) => ({
      ...shift,
      business_id: businessId,
      role_id: role.id,
      manager_id: manager.id,
      attire: requirements.attire,
      duties: requirements.duties,
      notes: requirements.notes,
    }));
    await adminApi.bulkCreateShifts(payload);
    message.success('Shifts posted');
    resetState();
    fetchPastShifts();
  };

  return (
    <div>
      <div>{manager.user.name}</div>
      <div>
        <Select
          value={businessId}
          options={manager.businesses.map((b) => ({
            value: b.id,
            label: b.name,
          }))}
          onChange={(value) => {
            setBusinessId(value);
          }}
        />
      </div>
      <div style={{ marginTop: 24 }}>
        {step === STEP_ROLE && (
          <RoleForm
            onNext={(newRole) => {
              handleRoleChange(newRole);
              setStep(STEP_REQUIREMENTS);
            }}
          />
        )}
        {step === STEP_REQUIREMENTS && (
          <RequirementsForm
            requirements={requirements}
            onBack={() => setStep(STEP_ROLE)}
            onChange={(newRequirements) => setRequirements(newRequirements)}
            onNext={() => setStep(STEP_SHIFTS)}
          />
        )}
        {step === STEP_SHIFTS && (
          <ShiftsScheduleForm
            shifts={shifts}
            pastShifts={rolePastShifts}
            onChange={(newShifts) => setShifts(newShifts)}
            onBack={() => setStep(STEP_REQUIREMENTS)}
            onSubmit={() => {
              setStep(STEP_REVIEW);
            }}
          />
        )}
        {step === STEP_REVIEW && (
          <ShiftsReview
            role={role}
            requirements={requirements}
            shifts={shifts}
            onBack={() => setStep(STEP_SHIFTS)}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default ShiftsPostingForm;
