import { UilArrowLeft, UilArrowRight } from '@iconscout/react-unicons';
import Typography from '../../../components/Typography';
import workEligibilityOptions from '../../../constants/workEligibilityOptions';
import colors from '../../../colors';
import analytics from '../../../utils/analytics';

const WorkEligibilityForm = ({ onBack, value, onSuccess }) => {
  const handleOptionSelected = (option) => {
    analytics.track('Work Eligibility Form Completed');
    onSuccess(option);
  };

  return (
    <div>
      <UilArrowLeft
        onClick={() => {
          analytics.track('Clicked Back Arrow', {
            context: 'Work Eligibility Form',
          });
          onBack();
        }}
        size={32}
        style={{ marginLeft: -8 }}
      />
      <div style={{ marginBottom: 40, marginTop: 24 }}>
        <Typography.H4 style={{ marginBottom: 0 }}>
          What is your work eligibility?
        </Typography.H4>
        <Typography.Body2>
          If you are unsure, please check your government issued NRIC.
        </Typography.Body2>
      </div>
      {workEligibilityOptions.map((option, i) => (
        <div
          role="button"
          tabIndex={i}
          key={option.value}
          onClick={() => {
            analytics.track('Clicked Work Eligibility Option');
            handleOptionSelected(option.value);
          }}
          style={{
            borderRadius: 10,
            border: `1px solid ${
              value === option.value ? colors.green : colors.grey5
            }`,
            padding: '17px 16px',
            boxShadow: `0 4px 8px 0 ${colors.black}22`,
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
        >
          <div>{option.label}</div>
          <UilArrowRight size={24} color={colors.grey} />
        </div>
      ))}
    </div>
  );
};

export default WorkEligibilityForm;
