import { Button, Checkbox, Form, Input } from 'antd';
import React from 'react';
import { UilArrowLeft } from '@iconscout/react-unicons';
import Typography from '../../../components/Typography';
import analytics from '../../../utils/analytics';
import routes from '../../../routes';

const NameAndEmailForm = ({ onBack, initialValues, onSuccess }) => {
  const [form] = Form.useForm();
  const handleFinish = (values) => {
    analytics.track('Name And Email Form Completed');
    onSuccess({
      name: values.name,
      email: values.email,
    });
  };

  return (
    <div>
      <UilArrowLeft
        onClick={() => {
          analytics.track('Clicked Back Arrow', {
            context: 'Name And Email Form',
          });
          onBack();
        }}
        size={32}
        style={{ marginLeft: -8 }}
      />
      <div style={{ marginBottom: 40, marginTop: 24 }}>
        <Typography.H4 style={{ marginBottom: 0 }}>Name & email</Typography.H4>
        <Typography.Body2>
          We’ll notify you of important changes, new features, or benefits.
        </Typography.Body2>
      </div>
      <Form
        form={form}
        layout="vertical"
        size="large"
        requiredMark={false}
        validateTrigger={['onSubmit', 'onBlur']}
        onFinish={handleFinish}
        initialValues={{
          name: initialValues.name,
          email: initialValues.email,
        }}
      >
        <Form.Item
          label="Name (as per NRIC)"
          name="name"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input placeholder="Roger Ng" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'This field is required' },
            {
              type: 'email',
              message: 'Please enter a valid email',
            },
          ]}
        >
          <Input placeholder="roger@uncle.com" />
        </Form.Item>
        <Form.Item
          name="termsOfService"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message:
                'Please accept the terms of service and privacy policy to continue',
            },
          ]}
        >
          <Checkbox>
            I agree to the{' '}
            <a
              href={routes.external.termsOfService}
              target="_blank"
              rel="noreferrer"
            >
              terms of service and privacy policy
            </a>
          </Checkbox>
        </Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          onClick={() => {
            analytics.track('Clicked Continue', {
              context: 'Name And Email Form',
            });
          }}
        >
          Continue
        </Button>
      </Form>
    </div>
  );
};

export default NameAndEmailForm;
