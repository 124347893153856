import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Divider, message, Typography as AntTypography } from 'antd';
import { Helmet } from 'react-helmet';
import lodash from 'lodash';

import businessApi from 'network/businessApi';
import shiftsApi from 'network/shiftsApi';

import Typography from 'components/Typography';
import Loader from 'components/Loader';

import analytics from 'utils/analytics';
import routes from 'routes';
import colors from 'colors';

import RoleDescription from './components/RoleDescription';
import RoleShiftBookingForm from './components/RoleShiftBookingForm';

const ShiftsBusinessPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [shifts, setShifts] = useState();
  const [business, setBusiness] = useState();

  const fetchData = async () => {
    setLoading(true);
    const { data: currentBusiness } = await businessApi.getById(id);

    const {
      data: { results: currentShifts },
    } = await shiftsApi.getUpcoming({ business_id: id });

    if (!currentBusiness) {
      message.info(`Sorry! This business is no longer on Staffie.`);
      history.push(routes.shiftsHome);
      return;
    }

    setBusiness(currentBusiness);
    setShifts(currentShifts);
    setLoading(false);
    analytics.page('Business Shifts', {
      business: currentBusiness,
    });
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  if (loading) return <Loader loadingText="Loading Business..." />;
  if (!business) return <></>;

  const groupedShifts = lodash.groupBy(shifts, (s) => s.role.name);
  const roles = Object.keys(groupedShifts);
  const isFromBookings = history.location?.state?.from.includes(
    routes.bookings,
  );
  return (
    <div>
      <Helmet>
        <title>{business.name} | Staffie</title>
        <meta
          name="description"
          content={`${business.cuisine} at ${business.area_name}`}
        />
        <meta property="og:image" content={business.image} />
      </Helmet>

      <section style={{ marginBottom: 48 }}>
        {isFromBookings ? (
          <Link to={routes.bookings} style={{ color: colors.green }}>
            ← Back to my shifts
          </Link>
        ) : (
          <Link to={routes.shiftsHome} style={{ color: colors.green }}>
            ← Back to all shifts
          </Link>
        )}
      </section>

      {/* Business overview */}
      <section style={{ marginBottom: 40 }}>
        <div style={{ marginBottom: 16 }}>
          <Typography.H3>{business.name}</Typography.H3>
          <Typography.Body1SharpGrotesk style={{ color: colors.grey }}>
            {business.cuisine} at {business.area_name}
          </Typography.Body1SharpGrotesk>
          {business.description && (
            <Typography.Body1 style={{ marginTop: 8, whiteSpace: 'pre-line' }}>
              <AntTypography.Paragraph
                ellipsis={{
                  rows: 1,
                  expandable: true,
                  symbol: 'more',
                  onExpand: () =>
                    analytics.track(
                      'Business Shifts - Clicked Expand Business Description',
                    ),
                }}
              >
                {business.description}
              </AntTypography.Paragraph>
            </Typography.Body1>
          )}
        </div>
        <div>
          <img
            src={business.image}
            alt={business.name}
            style={{ marginBottom: 8, width: '100%' }}
          />
          <iframe
            title={business.name}
            // Hacky solution to fix the "'" character issue with Google Maps embed
            src={business.google_maps_embed_src.replace('&#39;', '')}
            width="100%"
            height="150"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          />
        </div>
      </section>

      {/* Roles & their corresponding shifts for booking */}
      <section>
        {roles.length > 0 ? (
          roles.map((role) => {
            const roleShifts = groupedShifts[role];
            return (
              <div key={role}>
                <div className="auto-flow">
                  <RoleDescription
                    role={role}
                    duties={roleShifts[0].duties}
                    attire={roleShifts[0].attire}
                    shifts={roleShifts}
                  />
                  <RoleShiftBookingForm role={role} shifts={roleShifts} />
                </div>
                <Divider />
              </div>
            );
          })
        ) : (
          <div
            style={{
              background: colors.lightgrey,
              borderRadius: 3,
              padding: 16,
              marginBottom: 48,
            }}
          >
            <Typography.Body1>
              All the upcoming shifts for {business.name} have been booked.
              Check out our other shifts{' '}
              <Link to={routes.shiftsHome}>here</Link>
            </Typography.Body1>
          </div>
        )}
      </section>

      {/* Location of business */}
      <section style={{ marginBottom: 40 }}>
        <Typography.H4>Location</Typography.H4>
        <Typography.Body2Sans style={{ color: colors.grey }}>
          Where is {business.name}?
        </Typography.Body2Sans>
        <Typography.TextLink
          style={{ display: 'inline-block', marginTop: 8, marginBottom: 16 }}
          onClick={() =>
            analytics.track('Business Shifts - Clicked View On Google Maps')
          }
          href={business.google_maps_url}
          target="_blank"
          rel="noreferrer"
        >
          View on Google Maps ↗
        </Typography.TextLink>
        <div>
          <iframe
            title={business.name}
            // Hacky solution to fix the "'" character issue with Google Maps embed
            src={business.google_maps_embed_src.replace('&#39;', '')}
            width="100%"
            height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          />
        </div>
      </section>
    </div>
  );
};

export default ShiftsBusinessPage;
