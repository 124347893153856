import React, { useContext, useState } from 'react';
import lodash from 'lodash';
import { Button, Form, Space, Tooltip } from 'antd';

import routes from 'routes';
import ProContext from 'contexts/ProContext';
import Typography from 'components/Typography';
import mailSuccessLogo from 'assets/images/mail-success.png';
import analytics from 'utils/analytics';
import colors from 'colors';
import { normalizePhoneNumber } from 'utils/strings';

import ShiftBookingModal from './components/ShiftBookingModal';
import { getShiftDatetimeDurationString } from '../../../../../utils/timeUtils';

const RoleShiftBookingForm = ({ shifts }) => {
  const { profile } = useContext(ProContext);
  const [booked, setBooked] = useState(false);
  const [form] = Form.useForm();
  const [showShiftBookingModal, setShowShiftBookingModal] = useState(false);
  const [proMobile, setProMobile] = useState(profile?.user?.mobile || '');

  const orderedShifts = lodash.orderBy(shifts, ['start_time']);

  if (booked) {
    return (
      <div
        style={{
          backgroundColor: colors.lightgrey,
          padding: '24px 12px',
          borderRadius: 10,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          border: `1px solid ${colors.grey5}`,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Space
          direction="vertical"
          size={12}
          align="center"
          style={{ maxWidth: 287 }}
        >
          <Space direction="vertical" size={0} align="center">
            <img
              style={{ width: 64, height: 64 }}
              src={mailSuccessLogo}
              alt="logo"
            />
            <Typography.Body1 style={{ fontWeight: 'bold' }}>
              Booking received 🎉
            </Typography.Body1>
          </Space>
          <Typography.Body2Sans
            style={{ textAlign: 'center', color: colors.grey }}
          >
            Thank you! We'll get in touch to verify your booking soon. Please
            install our app as all shifts communications will happen through the
            app now.
          </Typography.Body2Sans>
          <Button block type="primary" size="large">
            <a href={routes.external.app} target="_blank" rel="noreferrer">
              Install app
            </a>
          </Button>
        </Space>
      </div>
    );
  }

  return (
    <>
      <div>
        <Form
          hideRequiredMark
          form={form}
          size="large"
          layout="vertical"
          initialValues={{
            phonePrefix: '+65',
          }}
          validateTrigger={['onSubmit']}
          onFinish={(values) => {
            analytics.track('Submitted Shift Booking Form');
            if (values.phoneNumber) {
              const mobile =
                values.phonePrefix + normalizePhoneNumber(values.phoneNumber);
              setProMobile(mobile);
            }
            setShowShiftBookingModal(true);
          }}
          style={{
            backgroundColor: colors.lightgrey,
            padding: 12,
            borderRadius: 10,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            border: `1px solid ${colors.grey5}`,
          }}
        >
          {/*<Form.Item*/}
          {/*  name="shifts"*/}
          {/*  label="Select the shift(s) you want"*/}
          {/*  rules={[{ required: true, message: 'This field is required' }]}*/}
          {/*  style={{ marginBottom: 8 }}*/}
          {/*>*/}
          {/*  <Checkbox.Group>*/}
          {/*    {orderedShifts.map((shift) => {*/}
          {/*      const shiftTimeString = getShiftDatetimeDurationString(*/}
          {/*        shift.start_time,*/}
          {/*        shift.hours,*/}
          {/*      );*/}
          {/*      const isAvailable = shift.booked_count < shift.staff_required;*/}
          {/*      const checkBox = (*/}
          {/*        <Checkbox*/}
          {/*          value={shift.id}*/}
          {/*          disabled={!isAvailable}*/}
          {/*          style={{*/}
          {/*            color: isAvailable ? 'initial' : colors.disable,*/}
          {/*            textDecoration: isAvailable ? 'initial' : 'line-through',*/}
          {/*          }}*/}
          {/*          onClick={() => {*/}
          {/*            analytics.track(*/}
          {/*              'Business Shifts - Clicked Shift Checkbox',*/}
          {/*            );*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          <Typography.Body2Sans style={{ display: 'inline' }}>*/}
          {/*            {shiftTimeString}*/}
          {/*          </Typography.Body2Sans>*/}
          {/*          <Typography.Body2Sans*/}
          {/*            style={{*/}
          {/*              display: 'inline',*/}
          {/*              marginLeft: 6,*/}
          {/*              color: isAvailable ? colors.grey : 'inherit',*/}
          {/*            }}*/}
          {/*          >*/}
          {/*            (S${shift.total_hourly_wage}/hr)*/}
          {/*          </Typography.Body2Sans>*/}
          {/*        </Checkbox>*/}
          {/*      );*/}

          {/*      return (*/}
          {/*        <div key={shift.id} style={{ marginBottom: 12 }}>*/}
          {/*          {isAvailable ? (*/}
          {/*            checkBox*/}
          {/*          ) : (*/}
          {/*            <Tooltip*/}
          {/*              title={*/}
          {/*                isAvailable ? null : 'Someone has booked this shift'*/}
          {/*              }*/}
          {/*              trigger={['click', 'hover']}*/}
          {/*              onClick={() => {*/}
          {/*                analytics.track(*/}
          {/*                  'Business Shifts - Clicked Disabled Shift',*/}
          {/*                );*/}
          {/*              }}*/}
          {/*            >*/}
          {/*              {checkBox}*/}
          {/*            </Tooltip>*/}
          {/*          )}*/}
          {/*        </div>*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </Checkbox.Group>*/}
          {/*</Form.Item>*/}
          <div>
            <Typography.H5SharpGrotesk style={{ marginBottom: 8 }}>
              Shifts available
            </Typography.H5SharpGrotesk>
            <ul style={{ paddingLeft: 0, listStylePosition: 'inside' }}>
              {orderedShifts.map((shift) => {
                const shiftTimeString = getShiftDatetimeDurationString(
                  shift.start_time,
                  shift.hours,
                );
                const isAvailable = shift.booked_count < shift.staff_required;
                const checkBox = (
                  <li
                    key={shift.id}
                    style={{
                      color: isAvailable ? 'initial' : colors.disable,
                      textDecoration: isAvailable ? 'initial' : 'line-through',
                    }}
                  >
                    <Typography.Body2Sans style={{ display: 'inline' }}>
                      {shiftTimeString}
                    </Typography.Body2Sans>
                    <Typography.Body2Sans
                      style={{
                        display: 'inline',
                        marginLeft: 6,
                        color: isAvailable ? colors.grey : 'inherit',
                      }}
                    >
                      (S${shift.total_hourly_wage}/hr)
                    </Typography.Body2Sans>
                  </li>
                );

                return (
                  <div key={shift.id} style={{ marginBottom: 12 }}>
                    {isAvailable ? (
                      checkBox
                    ) : (
                      <Tooltip
                        title={
                          isAvailable ? null : 'Someone has booked this shift'
                        }
                        trigger={['click', 'hover']}
                        onClick={() => {
                          analytics.track(
                            'Business Shifts - Clicked Disabled Shift',
                          );
                        }}
                      >
                        {checkBox}
                      </Tooltip>
                    )}
                  </div>
                );
              })}
            </ul>
          </div>
          {profile && (
            <Typography.Body2Sans
              style={{ color: colors.grey, marginBottom: 16 }}
            >
              {`Booking shifts as ${profile.user.name} ${profile.user.mobile}`}
            </Typography.Body2Sans>
          )}

          {/*{profile ? (*/}
          {/*  <Typography.Body2Sans*/}
          {/*    style={{ color: colors.grey, marginBottom: 16 }}*/}
          {/*  >*/}
          {/*    {`Booking shifts as ${profile.user.name} ${profile.user.mobile}`}*/}
          {/*  </Typography.Body2Sans>*/}
          {/*) : (*/}
          {/*  <>*/}
          {/*    <Typography.Body2Sans*/}
          {/*      style={{ color: colors.grey, marginBottom: 16 }}*/}
          {/*    >*/}
          {/*      Enter your phone number registered with Staffie to book these*/}
          {/*      shifts. We will send you an OTP to verify your account.*/}
          {/*    </Typography.Body2Sans>*/}

          {/*    <Form.Item name="phoneNumber" label="Enter your phone number">*/}
          {/*      <div style={{ display: 'flex', marginBottom: -24 }}>*/}
          {/*        <Form.Item name="phonePrefix">*/}
          {/*          <Select>*/}
          {/*            <Select.Option value="+65">🇸🇬 (SG) +65</Select.Option>*/}
          {/*            <Select.Option value="+60">🇲🇾 (MY) +60</Select.Option>*/}
          {/*          </Select>*/}
          {/*        </Form.Item>*/}
          {/*        <Form.Item*/}
          {/*          name="phoneNumber"*/}
          {/*          rules={[*/}
          {/*            { required: true, message: 'This field is required' },*/}
          {/*          ]}*/}
          {/*          style={{ marginLeft: 4, flex: 1 }}*/}
          {/*          normalize={(value) => {*/}
          {/*            return normalizePhoneNumber(value);*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          <Input placeholder="87778777" />*/}
          {/*        </Form.Item>*/}
          {/*      </div>*/}
          {/*    </Form.Item>*/}
          {/*  </>*/}
          {/*)}*/}
          <a href={routes.external.app} target="_blank" rel="noreferrer">
            <Button
              block
              size="large"
              type="primary"
              onClick={() => {
                analytics.track('Business Shifts - Clicked Book Shifts');
              }}
            >
              Book on the app
            </Button>
          </a>
        </Form>
      </div>

      {showShiftBookingModal && (
        <ShiftBookingModal
          shifts={form.getFieldValue('shifts')}
          mobileNumber={proMobile}
          onCancel={() => {
            setShowShiftBookingModal(false);
          }}
          onBookingSuccess={() => {
            setBooked(true);
          }}
        />
      )}
    </>
  );
};

export default RoleShiftBookingForm;
