import { Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import lodash from 'lodash';
import moment from 'moment';

import Typography from 'components/Typography';
import colors from 'colors';

const BusinessOverviewCard = ({ business }) => {
  const {
    image,
    name,
    cuisine,
    area_name: area,
    public_transport_distance: publicTransportDistance,
  } = business;

  const groupedShifts = lodash.groupBy(business.upcoming_shifts, 'role.name');

  const roles = Object.keys(groupedShifts);

  return (
    <>
      <div>
        <div style={{ marginBottom: 16 }}>
          <img
            src={image}
            alt={name}
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: 280,
              objectFit: 'cover',
              borderRadius: 10,
            }}
          />
        </div>
        <div style={{ marginBottom: 16 }}>
          <Typography.H4>{name}</Typography.H4>
          <Typography.Body2Sans style={{ color: colors.grey }}>
            {cuisine} at {area}
          </Typography.Body2Sans>
          {publicTransportDistance && (
            <Typography.Caption style={{ color: colors.grey }}>
              {publicTransportDistance}
            </Typography.Caption>
          )}
        </div>
        <Space direction="vertical" size={24}>
          {roles.map((role) => {
            const roleShifts = lodash.orderBy(groupedShifts[role], [
              'start_time',
            ]);
            const shiftsLeft = lodash.sum(
              roleShifts.map((s) => s.staff_required - s.booked_count),
            );

            const minWage = Math.min(
              ...roleShifts.map((s) => s.total_hourly_wage),
            );
            const maxWage = Math.max(
              ...roleShifts.map((s) => s.total_hourly_wage),
            );
            const hasWageRange = minWage !== maxWage;

            return (
              <Space align="start" size={12} key={role}>
                <UserOutlined
                  style={{
                    fontSize: 11,
                    borderRadius: '50%',
                    border: '1px solid',
                    color: colors.disable,
                  }}
                />
                <>
                  <div style={{ color: colors.black }}>
                    <div style={{ marginBottom: 8 }}>
                      {role} · S$
                      {hasWageRange
                        ? `${minWage}-${maxWage}`
                        : roleShifts[0].total_hourly_wage}
                      /hr ·{' '}
                      <span style={{ color: colors.green }}>
                        {shiftsLeft} {shiftsLeft === 1 ? 'shift' : 'shifts'}{' '}
                        left
                      </span>
                    </div>
                    {roleShifts.length > 0 && (
                      <Typography.Body2Sans style={{ color: colors.grey }}>
                        {roleShifts.map((shift, i) => {
                          const shiftDay = moment(shift.start_time).format(
                            'ddd D MMM',
                          );
                          const available =
                            shift.booked_count < shift.staff_required;

                          return (
                            <span key={shift.id}>
                              <span
                                style={{
                                  textDecoration: available
                                    ? 'initial'
                                    : 'line-through',
                                  color: available
                                    ? colors.grey
                                    : colors.disable,
                                }}
                              >
                                {shiftDay}
                              </span>
                              {i + 1 < roleShifts.length && <span>, </span>}
                            </span>
                          );
                        })}
                      </Typography.Body2Sans>
                    )}
                  </div>
                </>
              </Space>
            );
          })}
        </Space>
      </div>
    </>
  );
};

export default BusinessOverviewCard;
