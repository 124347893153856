import React, { useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import { isMobile } from 'react-device-detect';
import { Prompt } from 'react-router-dom';
import analytics from 'utils/analytics';
import routes from 'routes';
import MobileForm from './components/MobileForm';
import NameAndEmailForm from './components/NameAndEmailForm';
import WorkEligibilityForm from './components/WorkEligibilityForm';
import WorkExperienceForm from './components/WorkExperienceForm';
import prosApi from '../../network/prosApi';
import localStorageKeys from '../../constants/localStorageKeys';
import ProContext from '../../contexts/ProContext';
import AppInstallStep from './components/AppInstallStep';
import { normalizePhoneNumber } from '../../utils/strings';
import countryConfigs from '../../constants/countryConfigs';

const VARIANT = 'Signup v3';
const SignupPage = () => {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({});
  const { setProfile } = useContext(ProContext);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleSignup = async (finalValues) => {
    const utmContent = window.localStorage.getItem('utm_content');

    const referralString = window.localStorage.getItem('referral');

    const payload = {
      mobile: finalValues.mobile,
      email: finalValues.email,
      username: finalValues.email,
      name: finalValues.name,
      pro: {
        work_eligibility: finalValues.workEligibility,
        // Override the referral mobile if they entered their own mobile
        referral_mobile:
          finalValues.referralMobile === finalValues.mobile
            ? undefined
            : countryConfigs.SG.phonePrefix +
              normalizePhoneNumber(referralString),
        utm_content: utmContent,
      },
    };

    try {
      const signupResponse = await prosApi.signup(payload);
      analytics.track('Sign Up Successful');
      const authToken = signupResponse.data.auth_token;
      localStorage.setItem(localStorageKeys.AUTH_TOKEN, authToken);
      const proResponse = await prosApi.getMyself();
      setProfile(proResponse.data);
      analytics.identifyPro(proResponse.data);
    } catch (e) {
      const errorData = e.response.data;
      if (errorData && errorData.code === 'validation_error') {
        // TODO We probably want to send the user to a login page or something
        if (errorData.email || errorData.username || errorData.mobile) {
          message.error(
            <span>
              Account already exists.&nbsp;
              <a
                href={routes.login}
                style={{
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                  fontSize: 'inherit',
                }}
              >
                Login
              </a>
            </span>,
          );
          analytics.track('Sign Up Errored - Account Already Exists', payload);
        }
      } else {
        message.error('Could not submit. Please try again or contact support.');
        analytics.track('Sign Up Errored', payload);
      }
    }
  };

  useEffect(() => {
    analytics.page('Pro Signup', {
      isMobile,
      variant: VARIANT,
    });
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [step]);

  return (
    <div>
      <Prompt
        when={step > 1}
        message={() =>
          `Are you sure you want to go back? You will lose unsaved progress`
        }
      />
      {step === 1 ? (
        <MobileForm
          mobile={values.mobile}
          onSuccess={(mobile) => {
            setValues({
              ...values,
              mobile,
            });
            setStep(step + 1);
          }}
        />
      ) : step === 2 ? (
        <NameAndEmailForm
          onBack={() => {
            setStep(step - 1);
          }}
          initialValues={{ name: values.name, email: values.email }}
          onSuccess={async ({ name, email }) => {
            setValues({
              ...values,
              name,
              email,
            });
            setStep(step + 1);
          }}
        />
      ) : step === 3 ? (
        <WorkEligibilityForm
          onBack={() => {
            setStep(step - 1);
          }}
          value={values.workEligibility}
          onSuccess={async (workEligibility) => {
            const updatedValues = {
              ...values,
              workEligibility,
            };
            setValues(updatedValues);
            await handleSignup(updatedValues);
            setStep(step + 1);
          }}
        />
      ) : step === 4 ? (
        <WorkExperienceForm
          onBack={() => setStep(step - 1)}
          onSuccess={() => {
            setStep(step + 1);
          }}
        />
      ) : (
        <AppInstallStep />
      )}
    </div>
  );
};

export default SignupPage;
