import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';

import analytics from 'utils/analytics';
import prosApi from 'network/prosApi';
import ProContext from 'contexts/ProContext';

import OtpModal from 'components/OtpModal';
import CodeOfConductModal from './CodeOfConductModal';
import VerificationModal from './VerificationModal';
import BookingsSubmittedModal from './BookingsSubmittedModal';

const CODE_OF_CONDUCT_STEP = 'code_of_conduct_step';
const OTP_STEP = 'otp_step';
const PROFILE_VERIFICATION_STEP = 'profile_verification_step';
const BOOKINGS_SUBMITTED_STEP = 'bookings_submitted_step';

const ShiftBookingModal = ({
  shifts,
  mobileNumber,
  onCancel,
  onBookingSuccess,
}) => {
  const { profile, setProfile } = useContext(ProContext);
  const [step, setStep] = useState(profile ? CODE_OF_CONDUCT_STEP : OTP_STEP);

  const onOtpSuccess = async () => {
    const proResponse = await prosApi.getMyself();
    analytics.identifyPro(proResponse.data);

    ReactDOM.unstable_batchedUpdates(() => {
      setProfile(proResponse.data);
      setStep(CODE_OF_CONDUCT_STEP);
    });
  };

  return (
    <>
      {step === OTP_STEP ? (
        <OtpModal
          mobileNumber={mobileNumber}
          onCancel={onCancel}
          onSuccess={onOtpSuccess}
        />
      ) : step === CODE_OF_CONDUCT_STEP ? (
        <CodeOfConductModal
          shifts={shifts}
          onSuccess={() => {
            if (profile?.is_verified) {
              setStep(BOOKINGS_SUBMITTED_STEP);
              return;
            }
            setStep(PROFILE_VERIFICATION_STEP);
          }}
          onCancel={onCancel}
        />
      ) : step === PROFILE_VERIFICATION_STEP ? (
        <VerificationModal
          onSuccess={() => {
            setStep(BOOKINGS_SUBMITTED_STEP);
          }}
          onCancel={onCancel}
        />
      ) : (
        step === BOOKINGS_SUBMITTED_STEP && (
          <BookingsSubmittedModal
            onSuccess={onBookingSuccess}
            onCancel={onBookingSuccess}
          />
        )
      )}
    </>
  );
};

export default ShiftBookingModal;
