import React, { useContext } from 'react';
import { Avatar, Dropdown, Menu, Modal, Button, message } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import ProContext from 'contexts/ProContext';
import colors from 'colors';
import localStorageKeys from 'constants/localStorageKeys';

import Typography from 'components/Typography';
import analytics from 'utils/analytics';

const LOGOUT_KEY = '2';
const UserDropdownMenu = ({ avatarSize = 24 }) => {
  const { profile, setProfile } = useContext(ProContext);
  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      overlay={
        <Menu
          style={{ width: 130 }}
          onClick={async (e) => {
            switch (e.key) {
              case LOGOUT_KEY: {
                Modal.confirm({
                  closable: true,
                  centered: true,
                  title: 'Confirm logout?',
                  content: 'You will have to re-enter OTP for your next booking.',
                  okText: 'Confirm',
                  cancelText: 'Cancel',
                  onCancel: () => {
                    analytics.track('Logout Cancelled');
                  },
                  onOk: () => {
                    analytics.track('Logout');
                    message.success('Logout successful');
                    window.localStorage.removeItem(localStorageKeys.AUTH_TOKEN);
                    setProfile(null);
                  },
                });
                break;
              }
              default: {
                break;
              }
            }
          }}
        >
          <Menu.Item key={LOGOUT_KEY} onClick={() => analytics.track('Clicked Logout', { context: 'Header Nav Menu' })}>
            <Typography.Body2Sans style={{ color: colors.grey8 }}>Logout</Typography.Body2Sans>
          </Menu.Item>
        </Menu>
      }
    >
      <Button
        style={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: 42,
          height: 48,
          border: `1px solid ${colors.grey5}`,
          padding: '0px 8px 0px 18px',
        }}
      >
        <div style={{ paddingTop: 4 }}>
          <MenuOutlined style={{ fontSize: 16 }} />
        </div>
        <Avatar
          style={{ marginLeft: 14, backgroundColor: colors.peach, cursor: 'pointer', fontSize: 12 }}
          size={avatarSize}
        >
          {profile.user.name[0]}
        </Avatar>
      </Button>
    </Dropdown>
  );
};

export default UserDropdownMenu;
