export default {
  white: '#ffffff',
  black: '#000000',
  primaryText: '#262626',
  paleBlue: '#edf0ff',
  lightBlue: '#49AAFF',
  blue: '#023EC2',
  lightgrey: '#F5F5F5',
  disable: '#BFBFBF',
  dividers: '#E8E8E8',
  grey: '#8C8C8C',
  grey1: '#E5E6E8',
  grey2: '#A7ACAE',
  grey5: '#D9D9D9',
  grey8: '#595959',
  green: '#557571', // aka teracotta
  indigo: '#425AF5',
  purple1: '#E2E6FD',
  peach: '#FCC3AA',
  peachTerracotta: '#A3543D',
  beige: '#DFD5CB',
  orange1: '#FEF1D7',
  red: '#F06661',
  warning: '#F5993D',
  success: '#5BD0A2', // Light green
};
