import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import adminApi from '../../network/adminApi';

const RoleForm = ({ onNext }) => {
  const [roles, setRoles] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await adminApi.getRoles();

      setRoles(response.data.results);
    };

    fetchData();
  }, []);

  return (
    <div>
      <div style={{ maxWidth: 300 }}>
        {roles?.map((r) => (
          <div key={r.id} style={{ marginBottom: 6 }}>
            <Button block onClick={() => onNext(r)}>
              {r.name}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoleForm;
