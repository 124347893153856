import React from 'react';
import { Layout } from 'antd';
import { isMobile } from 'react-device-detect';

import Copyright from 'components/Copyright';
import HeaderContent from './components/HeaderContent';
import BottomNavMenu from './components/BottomNavMenu';

const { Header, Content, Footer } = Layout;

const CONTENT_WIDTH = 600;
const CONTENT_MIN_HEIGHT = 400;
const CONTENT_PADDING = 16;
const VERTICAL_PADDING = 24;

const AppLayout = ({ children }) => {
  return (
    <div>
      <Layout
        style={{
          padding: `${VERTICAL_PADDING}px ${CONTENT_PADDING}px`,
          margin: 'auto',
        }}
      >
        <div
          style={{
            maxWidth: CONTENT_WIDTH,
            width: '100%',
            margin: '0 auto',
          }}
        >
          <Header
            style={{
              marginBottom: 24,
              padding: 0,
            }}
          >
            <HeaderContent />
          </Header>
          <Content
            style={{
              minHeight: CONTENT_MIN_HEIGHT,
            }}
          >
            {children}
          </Content>
          <Footer style={{ padding: 0 }}>
            <Copyright />
          </Footer>
        </div>
      </Layout>

      {/* Sticky Bottom Menu */}
      {isMobile && (
        <div
          className="hide-on-desktop"
          style={{
            position: 'sticky',
            bottom: -1,
            backgroundColor: 'white',
            height: 68,
            boxShadow:
              '0px -4px 8px rgba(0, 0, 0, 0.1), inset 0px 1px 0px #D9D9D9',
          }}
        >
          <BottomNavMenu />
        </div>
      )}
    </div>
  );
};

export default AppLayout;
