import React from 'react';
import { Space } from 'antd';
import lodash from 'lodash';

import colors from 'colors';
import routes from 'routes';
import analytics from 'utils/analytics';

import Typography from 'components/Typography';

const RoleDescription = ({ role, duties, attire, shifts }) => {
  const minWage = Math.min(...shifts.map((s) => s.total_hourly_wage));
  const maxWage = Math.max(...shifts.map((s) => s.total_hourly_wage));
  const hasWageRange = minWage !== maxWage;

  const wage = hasWageRange ? `${minWage}-${maxWage}` : maxWage;

  const shiftEarnings = shifts.map((s) => s.total_hourly_wage * s.total_work_hours);
  const minEarnings = Math.min(...shiftEarnings);
  const maxEarnings = lodash.sum(shifts.map((s) => s.total_hourly_wage * s.total_work_hours));

  const shiftsLeft = lodash.sum(shifts.map((s) => s.staff_required - s.booked_count));

  return (
    <Space direction="vertical" size={16} style={{ minWidth: 80 }}>
      <div>
        <Typography.Body1SharpGrotesk>
          {role} · S${wage}/hr
        </Typography.Body1SharpGrotesk>
        <Typography.Body2Sans style={{ color: colors.green }}>
          {shiftsLeft} {shiftsLeft === 1 ? 'shift' : 'shifts'} left
        </Typography.Body2Sans>
        <Typography.Body2Sans style={{ color: colors.grey }}>
          Potential earnings {maxEarnings !== minEarnings ? `S$${minEarnings}-S$${maxEarnings}` : `S$${maxEarnings}`}
        </Typography.Body2Sans>
      </div>

      {duties && (
        <div>
          Duties:
          <Typography.Body2>{duties}</Typography.Body2>
        </div>
      )}

      {attire && (
        <div>
          Attire:
          <Typography.Body2>{attire}</Typography.Body2>
        </div>
      )}

      <div>
        Before you book:
        <Typography.Body2>
          <ul style={{ paddingLeft: 24 }}>
            {[
              "Check your schedule to be sure you're available.",
              "Check the location and consider how you'll get there.",
            ].map((check) => (
              <li key={check}>{check}</li>
            ))}
          </ul>
        </Typography.Body2>
      </div>

      {shifts[0].notes && (
        <div>
          Things to note:
          <Typography.Body2>{shifts[0].notes}</Typography.Body2>
        </div>
      )}

      <div>
        <a
          href={routes.external.faq}
          target="_blank"
          rel="noreferrer"
          style={{ color: colors.green }}
          onClick={() => analytics.track('Role Description - Clicked FAQ')}
        >
          Read more on our FAQ ↗
        </a>
      </div>
    </Space>
  );
};

export default RoleDescription;
