import React from 'react';
import { Space, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Typography from 'components/Typography';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Loader = ({ loadingText = 'loading...' }) => (
  <div style={{ display: 'flex', justifyContent: 'center', padding: 32 }}>
    <Space direction="vertical" align="center">
      <Spin indicator={antIcon} style={{ marginBottom: 8 }} />
      {loadingText && <Typography.Caption>{loadingText}</Typography.Caption>}
    </Space>
  </div>
);

export default Loader;
