import { Space } from 'antd';

import moment from 'moment';

import logoWithBrandName from 'assets/images/staffie-logos/logo-with-name-grey.png';
import Typography from './Typography';
import colors from '../colors';

const Copyright = ({ direction = 'vertical' }) => {
  return (
    <Space direction={direction} size={24}>
      <img
        src={logoWithBrandName}
        alt="staffie-logo-with-name"
        style={{ width: 140, height: 'auto' }}
      />
      <Typography.Body2Sans style={{ color: colors.grey }}>
        &copy; {moment().format('YYYY')} Staffie Pte. Ltd. (202118384K){' '}
      </Typography.Body2Sans>
    </Space>
  );
};

export default Copyright;
