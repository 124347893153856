import { datetimeFormat, timeFormat } from 'constants/datetime';
import moment from 'moment';

const trimZeroes = (timeString) => {
  return timeString.replace(':00', '');
};

// TODO: Discuss whether to use 12/24 hour format
export const getShiftDatetimeDurationString = (startTime, hours) => {
  return `${trimZeroes(moment(startTime).format(datetimeFormat))}-${trimZeroes(
    moment(startTime).add(hours, 'hours').format(timeFormat),
  )}`;
};

export const getTimeDurationString = (startTime, hours) => {
  return `${trimZeroes(moment(startTime).format(timeFormat))}-${trimZeroes(
    moment(startTime).add(hours, 'hours').format(timeFormat),
  )}`;
};
