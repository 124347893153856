import appStoreSrc from 'assets/images/app-store-badge.png';
import playStoreSrc from 'assets/images/play-store-badge.png';
import Typography from '../../../components/Typography';
import routes from '../../../routes';
import analytics from '../../../utils/analytics';

const AppInstallStep = () => {
  return (
    <div>
      <div style={{ marginTop: 24 }}>
        <Typography.H4 style={{ marginBottom: 0 }}>
          Welcome on board!
        </Typography.H4>
        <Typography.Body2>
          Just one last step. Download the Staffie app and log in to view
          available shifts.
        </Typography.Body2>
      </div>
      <div style={{ display: 'flex' }}>
        <a
          onClick={() => {
            analytics.track('Clicked App Store Badge', {
              context: 'App Install Step',
            });
          }}
          target="_blank"
          href={routes.external.appStore}
          rel="noreferrer"
        >
          <img src={appStoreSrc} alt="App Store" style={{ width: 128 }} />
        </a>
        <a
          onClick={() => {
            analytics.track('Clicked Play Store Badge', {
              context: 'App Install Step',
            });
          }}
          style={{ marginLeft: 8 }}
          target="_blank"
          href={routes.external.playStore}
          rel="noreferrer"
        >
          <img src={playStoreSrc} alt="Play Store" style={{ width: 128 }} />
        </a>
      </div>
    </div>
  );
};

export default AppInstallStep;
