const countryConfigs = {
  SG: {
    phonePrefix: '+65',
    currencyPrefix: 'S$',
    mobileNumberLength: 8, // Excludes prefix
  },
  MY: {
    phonePrefix: '+60',
    currencyPrefix: 'RM',
    mobileNumberLength: 9,
  },
};

export default countryConfigs;
