import React, { useContext, useEffect, useState } from 'react';
import lodash from 'lodash';
import { Select } from 'antd';
import ProContext from '../../contexts/ProContext';
import adminApi from '../../network/adminApi';
import ShiftsPostingForm from './ShiftsPostingForm';

const AdminPage = () => {
  const [managers, setManagers] = useState();
  const [manager, setManager] = useState();
  const { profile } = useContext(ProContext);

  if (!profile?.user.is_staff) {
    return <div>What are you doing here?</div>;
  }

  const fetchManagers = async (search, page) => {
    const managersResponse = await adminApi.getManagers({ search, page });
    const currResults = managersResponse.data.results;

    // Use recursion to fetch all pages
    if (managersResponse.data.next) {
      // Fetch all the other pages
      const nextResults = await fetchManagers(search, (page || 1) + 1);
      return lodash.uniqBy([...currResults, ...nextResults], (x) => x.id);
    }
    return currResults;
  };

  useEffect(() => {
    const fetchData = async () => {
      const allManagers = await fetchManagers();
      setManagers(allManagers.filter((m) => m.businesses.length > 0));
    };

    fetchData();
  }, []);

  return (
    <div>
      <div>Admin Page</div>
      {managers && (
        <Select
          showSearch
          placeholder="Select manager"
          style={{ width: 200 }}
          value={manager?.id}
          options={managers?.map((m) => ({ value: m.id, label: m.user.name }))}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(value) => {
            setManager(managers.find((m) => m.id === value));
          }}
        />
      )}
      {manager && <ShiftsPostingForm manager={manager} />}
    </div>
  );
};

export default AdminPage;
