import React from 'react';
import { Button, Modal } from 'antd';
import analytics from 'utils/analytics';
import Typography from 'components/Typography';
import routes from 'routes';

const BookingsSubmittedModal = ({ onSuccess, onCancel }) => (
  <Modal
    visible
    onCancel={onCancel}
    title={
      <Typography.Body1SharpGrotesk>
        Install the Staffie app 📱
      </Typography.Body1SharpGrotesk>
    }
    footer={
      <a
        href={routes.external.app}
        onClick={() => {
          analytics.track('Clicked Get The App');
          onSuccess();
        }}
        target="_blank"
        rel="noreferrer"
      >
        <Button type="primary" block size="large">
          Get the app
        </Button>
      </a>
    }
  >
    <Typography.Body2>
      Install the Staffie app on your phone and turn on notifications. We'll use
      the app to inform you about any changes to your shift.
    </Typography.Body2>
  </Modal>
);

export default BookingsSubmittedModal;
