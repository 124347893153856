import React, { useState } from 'react';
import { Button, Modal } from 'antd';

import Typography from 'components/Typography';

import analytics from 'utils/analytics';
import routes from 'routes';
import colors from 'colors';

import onboardingStepOneImg from 'assets/images/onboarding/onboarding-step-1.png';
import onboardingStepTwoImg from 'assets/images/onboarding/onboarding-step-2.png';
import onboardingStepThreeImg from 'assets/images/onboarding/onboarding-step-3.png';
import onboardingStepFourImg from 'assets/images/onboarding/onboarding-step-4.png';

const OnboardingModal = ({ visible, onCancel, onComplete, closable = true }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const incrementStep = () => {
    analytics.track('Clicked Next', {
      component: 'Onboarding Modal',
      page: 'Shifts',
      currentStep: currentStepIndex,
    });
    const nextStepIndex = currentStepIndex + 1;
    setCurrentStepIndex(nextStepIndex);
  };

  if (!visible) return <></>;

  const steps = [
    {
      title: 'Find a business',
      description: 'Find restaurants with your preferred area or role.',
      img: onboardingStepOneImg,
      alt: 'find-business-img',
    },
    {
      title: 'Book shifts',
      description: 'Check the days that you can work and book those shifts.',
      img: onboardingStepTwoImg,
      alt: 'book-shifts-img',
    },
    {
      title: 'Verify your booking',
      description: 'The Staffie team will verify and connect you with the business.',
      img: onboardingStepThreeImg,
      alt: 'verify-booking-img',
    },
    {
      title: 'Work and get Paid',
      description: "Show up, work hard, and we'll pay you through bank transfer the following Monday.",
      img: onboardingStepFourImg,
      alt: 'get-paid-img',
    },
  ];

  const currentStep = steps[currentStepIndex];
  const isLastStep = currentStepIndex === steps.length - 1;
  return (
    <Modal
      centered
      destroyOnClose
      closable={closable}
      visible={visible}
      footer={null}
      onCancel={isLastStep ? onComplete : onCancel}
      width={350}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography.Body1SharpGrotesk style={{ fontWeight: 'bold', color: colors.grey, marginBottom: 8 }}>
          How it Works
        </Typography.Body1SharpGrotesk>
        <Typography.H4 style={{ marginBottom: 32 }}>4 easy steps to get work</Typography.H4>
        <img src={currentStep.img} style={{ width: '100%', height: 'auto', marginBottom: 24 }} alt={currentStep.alt} />
        <Typography.H5SharpGrotesk style={{ color: colors.green }}>
          {`STEP ${currentStepIndex + 1}`}
        </Typography.H5SharpGrotesk>
        <Typography.Body1SharpGrotesk>{currentStep.title}</Typography.Body1SharpGrotesk>
        <Typography.Body2 style={{ marginBottom: 32 }}>{currentStep.description}</Typography.Body2>

        {isLastStep ? (
          <>
            <Button block type="primary" size="large" onClick={onComplete} style={{ marginBottom: 24 }}>
              Let's go
            </Button>
            <Typography.TextLink
              href={routes.external.faq}
              target="blank"
              rel="noreferrer"
              onClick={() =>
                analytics.track('Clicked FAQ', {
                  page: 'Shifts',
                  component: 'Onboarding Modal',
                })
              }
            >
              Read more on our FAQ ↗
            </Typography.TextLink>
          </>
        ) : (
          <Button
            block
            type="secondary"
            size="large"
            onClick={incrementStep}
            style={{ color: colors.green, fontWeight: 'bold' }}
          >
            Next →
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default OnboardingModal;
