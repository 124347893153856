const routes = {
  // Public
  signup: '/signup',
  login: '/login',

  // Auth
  bookings: '/bookings',
  shiftsHome: '/shifts',
  shiftBusiness: '/shifts/:id/:safename',
  admin: '/admin',

  external: {
    faq: 'https://www.staffie.app/faq',
    app: 'https://staffie.app.link/install',
    appStore: 'https://apps.apple.com/sg/app/staffie/id1572979814',
    playStore: 'https://play.google.com/store/apps/details?id=app.staffie.pro',
    termsOfService: 'https://www.staffie.app/terms-of-service',
    privacyPolicy: 'https://www.staffie.app/privacy-policy',
  },
};

export default routes;
