/* eslint-disable no-console */
import { environments, getEnvironment } from '../config';

const shouldTrack = () => {
  return (
    !window.localStorage.getItem('tracking_opt_out') &&
    window.analytics &&
    getEnvironment() === environments.PRODUCTION
  );
};

const track = (name, properties) => {
  if (!shouldTrack()) {
    return console.debug('track', name, properties);
  }
  return window.analytics?.track(name, properties);
};

const page = (name, properties) => {
  if (!shouldTrack()) {
    return console.debug('page', name, properties);
  }
  return window.analytics?.page(name, properties);
};

const identifyPro = (pro) => {
  const data = {
    date_of_birth: pro.date_of_birth,
    pro_id: pro.id,
    is_verified: pro.is_verified,
    work_eligibility: pro.work_eligibility,
    referral_mobile: pro.referral_mobile,
    school: pro.school,
    // User level fields
    mobile: pro.user.mobile,
    name: pro.user.name,
  };

  if (!shouldTrack()) {
    return console.debug('identify', pro.user.id, data);
  }

  return window.analytics?.identify(pro.user.id, data);
};

export default {
  track,
  page,
  identifyPro,
};
