import styled from 'styled-components';
import colors from 'colors';

const breakpoints = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};

// Standardised font sizes
// All text in the website should be catalogued here for consistency
const H1Big = styled.h1`
  font-family: 'Sharp Grotesk', sans-serif;
  font-size: 46px;
  line-height: 54px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 72px;
    line-height: 72px;
  }
`;

const H2Big = styled.h2`
  font-family: 'Sharp Grotesk', sans-serif;
  font-size: 34px;
  line-height: 43px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 46px;
    line-height: 54px;
  }
`;

const H1 = styled.h1`
  font-family: 'Sharp Grotesk', sans-serif;
  font-weight: 400;
  font-size: 72px;
  line-height: 72px;
`;

const H2 = styled.h2`
  font-family: 'Sharp Grotesk', sans-serif;
  font-size: 46px;
  line-height: 54px;
  font-weight: 400;
`;

const H3 = styled.h3`
  font-family: 'Sharp Grotesk', sans-serif;
  font-size: 30px;
  line-height: 38px;
  font-weight: 400;
`;

const H4 = styled.h4`
  font-family: 'Sharp Grotesk', sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
`;

const H5 = styled.h5`
  font-family: 'Sharp Grotesk', sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
`;

const H5SharpGrotesk = styled.h5`
  font-family: 'Sharp Grotesk Medium20', sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
`;

const Body1 = styled.div`
  font-family: 'Simula', sans-serif;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
`;

const Body2 = styled.div`
  font-family: 'Simula', sans-serif;
  font-variant-numeric: lining-nums;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
`;

const Body2Sans = styled.div`
  font-family: 'Sharp Grotesk Book20', sans-serif;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
`;

const TextLink = styled.a`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${colors.green};
  cursor: pointer;
`;

const Body1SharpGrotesk = styled.div`
  font-family: 'Sharp Grotesk Medium20', sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
`;

const PreTitle = styled.div`
  font-family: 'Sharp Grotesk', sans-serif;
  font-size: 14px;
  line-height: 22px;
`;

const Caption = styled.div`
  font-family: 'Sharp Grotesk Book20', sans-serif;
  font-size: 12px;
  line-height: 20px;
`;

const LargeParagraph = styled.div`
  font-family: 'Simula', sans-serif;
  font-size: 24px;
  line-height: 38px;

  p {
    margin-bottom: 24px;
  }
`;

export default {
  H1Big,
  H2Big,
  H1,
  H2,
  H3,
  H4,
  H5,
  H5SharpGrotesk,
  LargeParagraph,
  TextLink,
  Body1,
  Body1SharpGrotesk,
  Body2,
  Body2Sans,
  Caption,
  PreTitle,
};
