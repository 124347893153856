import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import routes from 'routes';

import ShiftsListPage from './ShiftsListPage';
import ShiftsBusinessPage from './ShiftsBusinessPage';

const shiftsPages = [
  { exact: true, path: routes.shiftBusiness, component: ShiftsBusinessPage },
  { exact: true, path: routes.shiftsHome, component: ShiftsListPage },
];

const ShiftsView = () => {
  return (
    <Switch>
      {shiftsPages.map(({ exact, path, component: Component }) => (
        <Route key={path} exact={exact} path={path} render={() => <Component />} />
      ))}
      <Redirect to={routes.shiftsHome} />
    </Switch>
  );
};

export default ShiftsView;
