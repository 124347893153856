import baseApi from './baseApi';

const getAll = (params) => {
  return baseApi.get('/businesses/', { params });
};

const getById = (id) => {
  return baseApi.get(`/businesses/${id}/`);
};

const listWithUpcomingShifts = (params) => {
  return baseApi.get('/businesses/with-upcoming-shifts/', { params });
};

export default { getAll, getById, listWithUpcomingShifts };
