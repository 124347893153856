import React from 'react';
import { Button } from 'antd';
import routes from '../routes';
import colors from '../colors';
import Typography from './Typography';
import analytics from '../utils/analytics';

const AppBanner = ({
  title = 'Get our app',
  body = 'Staffie is now on Android and iOS. Install the app for a better experience!',
  buttonText = 'Get the app',
}) => (
  <div
    style={{
      padding: 24,
      backgroundColor: colors.lightgrey,
      borderRadius: 10,
      position: 'relative',
    }}
  >
    <Typography.H4>{title}</Typography.H4>
    <Typography.Body2 style={{ marginBottom: 16 }}>{body}</Typography.Body2>
    <a
      href={routes.external.app}
      onClick={() => {
        analytics.track('Clicked App Banner Link');
      }}
      target="_blank"
      rel="noreferrer"
    >
      <Button type="primary" block size="large">
        {buttonText}
      </Button>
    </a>
  </div>
);

export default AppBanner;
