import { useState, useEffect, useContext } from 'react';
import { Space, Row, Col, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';

import ProContext from 'contexts/ProContext';
import analytics from 'utils/analytics';
import colors from 'colors';
import routes from 'routes';
import logo from 'assets/images/staffie-logos/logo.png';

import Typography from 'components/Typography';
import OnboardingModal from 'components/OnboardingModal';

import UserDropdownMenu from './UserDropdownMenu';

const ONBOARDING_KEY = 'has_onboarded';
const HAS_COMPLETE_ONBOARDING_VALUE = 'completed';

const HeaderContent = () => {
  const history = useHistory();
  const { profile } = useContext(ProContext);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  useEffect(() => {
    const checkOnboardingState = () => {
      const hasOnboardedValue = window.localStorage.getItem(ONBOARDING_KEY);
      if (hasOnboardedValue !== HAS_COMPLETE_ONBOARDING_VALUE) {
        analytics.track('Modal Opened', {
          page: 'Shifts',
          component: 'Onboarding Modal',
        });
        setShowOnboardingModal(true);
      }
    };
    checkOnboardingState();
  }, []);

  const inFindShifts = history.location.pathname.includes(routes.shiftsHome);
  const inBookings = history.location.pathname.includes(routes.bookings);
  const menuActiveStyle = { borderBottom: `2px solid ${colors.black}` };

  return (
    <>
      <Row justify="space-between">
        <Col>
          <Link
            to={routes.shiftsHome}
            onClick={() => {
              analytics.track('Nav Bar - Clicked Logo');
            }}
          >
            <img
              src={logo}
              alt="staffie-logo"
              style={{ height: 40, width: 'auto' }}
            />
          </Link>
        </Col>

        <Col className="show-on-desktop">
          <Space size={32}>
            <Button
              type="link"
              style={{ padding: 0, ...(inFindShifts ? menuActiveStyle : {}) }}
            >
              <Link to={routes.shiftsHome}>
                <Typography.Body1 style={{ color: colors.black }}>
                  Find shifts
                </Typography.Body1>
              </Link>
            </Button>
            <Button
              type="link"
              style={{ padding: 0, ...(inBookings ? menuActiveStyle : {}) }}
            >
              <Link to={routes.bookings}>
                <Typography.Body1 style={{ color: colors.black }}>
                  My shifts
                </Typography.Body1>
              </Link>
            </Button>
          </Space>
        </Col>

        <Col style={{ display: 'flex' }}>
          <Button
            size="large"
            shape="round"
            style={{ border: 'none', padding: 0, height: '100%' }}
            icon={
              <QuestionCircleOutlined
                style={{ color: colors.green, fontSize: 26, paddingTop: 4 }}
              />
            }
            onClick={() => {
              analytics.track('Clicked Button', {
                page: 'Shifts',
                component: 'Question',
                context: 'Header Nav Menu',
              });
              setShowOnboardingModal(true);
            }}
          />
          <section style={{ margin: 'auto auto auto 16px' }}>
            {profile ? (
              <UserDropdownMenu avatarSize={32} />
            ) : (
              <Button
                style={{
                  borderRadius: 42,
                  height: 48,
                  border: `1px solid ${colors.grey5}`,
                }}
                type="link"
              >
                <Link
                  to={routes.login}
                  onClick={() => {
                    analytics.track('Clicked Login', {
                      context: 'Header Nav Menu',
                    });
                  }}
                >
                  Log in
                </Link>
              </Button>
            )}
          </section>
        </Col>
      </Row>

      {/* Modals */}
      {showOnboardingModal && (
        <OnboardingModal
          visible={showOnboardingModal}
          closable={false}
          onCancel={() => {
            analytics.track('Clicked Close', {
              page: 'Shifts',
              component: 'Onboarding Modal',
              completed: false,
            });
            window.localStorage.setItem(
              ONBOARDING_KEY,
              HAS_COMPLETE_ONBOARDING_VALUE,
            );
            setShowOnboardingModal(false);
          }}
          onComplete={() => {
            analytics.track('Clicked Completed', {
              page: 'Shifts',
              component: 'Onboarding Modal',
              completed: true,
            });
            window.localStorage.setItem(
              ONBOARDING_KEY,
              HAS_COMPLETE_ONBOARDING_VALUE,
            );
            setShowOnboardingModal(false);
          }}
        />
      )}
    </>
  );
};

export default HeaderContent;
