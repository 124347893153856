import baseApi from './baseApi';

const get = (params) => {
  return baseApi.get('/bookings/', { params });
};

const bulkCreate = (shiftIds) => {
  return baseApi.post('/bookings/bulk-create/', {
    shift_ids: shiftIds,
  });
};

export default { bulkCreate, get };
