import baseApi from './baseApi';

const createOtp = (mobileNumber, signup = false) => {
  return baseApi.post('/users/create-otp/', {
    mobile: mobileNumber,
    signup,
  });
};

const checkOtp = (mobileNumber, otpCode) => {
  return baseApi.post('/users/check-otp/', {
    mobile: mobileNumber,
    otp_code: otpCode,
  });
};

export default {
  createOtp,
  checkOtp,
};
