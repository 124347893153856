import React, { useState } from 'react';
import { Button } from 'antd';
import { getTimeDurationString } from '../../utils/timeUtils';
import colors from '../../colors';
import ShiftEditModal from './ShiftEditModal';

const ShiftCard = ({ shift, onChange, onDelete }) => {
  const [shiftModalVisible, setShiftModalVisible] = useState(false);
  const isExistingShift = !!shift.id;

  return (
    <div
      style={{
        padding: 8,
        background: isExistingShift ? colors.lightgrey : colors.beige,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div style={{ display: 'grid', gridAutoFlow: 'column', gridGap: 8 }}>
        <div>{shift.staff_required}x&nbsp;</div>
        <div>{getTimeDurationString(shift.start_time, shift.hours)}</div>
        <div>S${shift.hourly_wage}/hr</div>
      </div>
      {!isExistingShift && (
        <Button
          role="button"
          type="link"
          onClick={() => setShiftModalVisible(true)}
        >
          Edit
        </Button>
      )}
      <ShiftEditModal
        visible={shiftModalVisible}
        shift={shift}
        onChange={(updatedShift) => {
          onChange(updatedShift);
          setShiftModalVisible(false);
        }}
        onDelete={() => {
          onDelete();
          setShiftModalVisible(false);
        }}
        onClose={() => setShiftModalVisible(false)}
      />
    </div>
  );
};

export default ShiftCard;
