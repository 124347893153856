import baseApi from './baseApi';

const signup = (data) => {
  return baseApi.post('/pros/signup/', data);
};

const createExperience = (data) => {
  return baseApi.post('/pros/experiences/', data);
};

const update = (proId, payload) => {
  return baseApi.patch(`/pros/${proId}/`, {
    ...payload,
  });
};

const getMyself = () => {
  return baseApi.get('/pros/me/');
};

export default { signup, createExperience, getMyself, update };
