import React from 'react';
import { Button } from 'antd';
import lodash from 'lodash';
import { getShiftDatetimeDurationString } from '../../utils/timeUtils';

const ShiftsReview = ({ role, requirements, shifts, onBack, onSubmit }) => {
  return (
    <div>
      <div>{role.name}</div>
      <div>{requirements.duties}</div>
      <div>{requirements.attire}</div>
      <div>{requirements.notes}</div>
      {lodash.orderBy(shifts, 'start_time').map((shift) => (
        <div key={shift.uuid}>
          <div>
            {getShiftDatetimeDurationString(shift.start_time, shift.hours)}
          </div>
          <div>
            S${shift.hourly_wage}/hr | {shift.break_hours} hr break
          </div>
        </div>
      ))}
      <Button type="secondary" onClick={onBack}>
        Back
      </Button>
      <Button type="primary" onClick={onSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default ShiftsReview;
