import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import { SearchOutlined, CalendarOutlined } from '@ant-design/icons';

import routes from 'routes';
import colors from 'colors';
import analytics from 'utils/analytics';

const BottomNavMenu = () => {
  const history = useHistory();

  const menuItems = [
    {
      title: 'Find shifts',
      icon: <SearchOutlined style={{ fontSize: 16 }} />,
      isActive: history.location.pathname === routes.shiftsHome,
      onClick: () => {
        analytics.track('Clicked Find Shifts', { context: 'Bottom Nav Menu' });
        if (history.location?.state?.from.includes(routes.shiftsHome)) {
          history.push(history.location.state.from);
        } else {
          history.push(routes.shiftsHome);
        }
      },
    },
    {
      title: 'My shifts',
      icon: <CalendarOutlined style={{ fontSize: 16 }} />,
      isActive: history.location.pathname === routes.bookings,
      onClick: () => {
        analytics.track('Clicked My Shifts', { context: 'Bottom Nav Menu' });
        history.push(routes.bookings, { from: `${history.location.pathname}/${history.location?.search || ''}` });
        window.scrollTo(0, 0);
      },
    },
  ];

  return (
    <Row justify="space-around" style={{ height: '100%' }}>
      {menuItems.map(({ title, icon, isActive, onClick }) => {
        const menuStyle = isActive ? { color: colors.green } : {};
        return (
          <Col key={title} span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              block
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'inherit',
                ...menuStyle,
              }}
              type="text"
              onClick={onClick}
            >
              <span>{icon}</span>
              <span>{title}</span>
            </Button>
          </Col>
        );
      })}
    </Row>
  );
};

export default BottomNavMenu;
