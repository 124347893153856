import React, { useState } from 'react';
import { Button, Divider, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import Typography from 'components/Typography';

import analytics from 'utils/analytics';
import prosApi from 'network/prosApi';
import { UilArrowLeft } from '@iconscout/react-unicons';

const NO_EXPERIENCE = 'No experience';

const WorkExperienceForm = ({ onBack, email, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    const promises = values.workExperiences.map((exp) => {
      return prosApi.createExperience({
        role_name: exp.role,
        duration: exp.duration,
        places_worked: exp.placesWorked,
        responsibilities: exp.responsibilities,
      });
    });
    await Promise.all(promises);
    setLoading(false);
    // New analytics event (renamed)
    analytics.track('Work Experience Form Completed');
    // Legacy analytics event
    analytics.track('Work Experience Form - Submitted', { email, ...values });
    onSuccess();
  };

  return (
    <div>
      <UilArrowLeft onClick={onBack} size={32} style={{ marginLeft: -8 }} />
      <div style={{ marginBottom: 40, marginTop: 24 }}>
        <Typography.H4 style={{ marginBottom: 0 }}>
          Work experience
        </Typography.H4>
        <Typography.Body2>
          Tell us about your F&B work experience so that we can send you
          suitable shifts.
        </Typography.Body2>
      </div>
      <Form
        form={form}
        layout="vertical"
        size="large"
        requiredMark={false}
        validateTrigger={['onSubmit']}
        onFinish={onFinish}
      >
        <Form.List name="workExperiences" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, i) => (
                <div key={field.key}>
                  <Form.Item
                    name={[field.name, 'role']}
                    label="Role"
                    fieldKey={[field.fieldKey, 'role']}
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                  >
                    <Select placeholder="Select role">
                      {[
                        'Server',
                        'Barista',
                        'Bartender',
                        'Cook',
                        'Kitchen Assistant',
                        'Dishwasher',
                      ].map((value) => (
                        <Select.Option key={value} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'duration']}
                    label="Experience"
                    fieldKey={[field.fieldKey, 'duration']}
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                  >
                    <Select
                      placeholder="Select one"
                      onChange={() => {
                        // Hacky way to force a rerender
                        form.setFieldsValue({});
                      }}
                    >
                      {[
                        // NO_EXPERIENCE,
                        'Less than 3 months',
                        '3 to 6 months',
                        '6 to 12 months',
                        '1 to 2 years',
                        'More than 2 years',
                      ].map((value) => (
                        <Select.Option key={value} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {form.getFieldValue([
                    'workExperiences',
                    field.name,
                    'duration',
                  ]) !== NO_EXPERIENCE && (
                    <>
                      <Form.Item
                        name={[field.name, 'placesWorked']}
                        label="Places Worked"
                        fieldKey={[field.fieldKey, 'placesWorked']}
                        rules={[
                          { required: true, message: 'This field is required' },
                        ]}
                      >
                        <Input
                          autoSize
                          placeholder="e.g. The Masons Table, Outback Steakhouse, Jewel Coffee"
                        />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, 'responsibilities']}
                        label="Responsibilities"
                        fieldKey={[field.fieldKey, 'responsibilities']}
                        rules={[
                          { required: true, message: 'This field is required' },
                        ]}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 3 }}
                          placeholder="e.g. Order taking, customer service, food prep"
                        />
                      </Form.Item>
                    </>
                  )}
                  {i > 0 && (
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        danger
                        type="text"
                        onClick={() => {
                          analytics.track(
                            'Work Experience Form - Clicked Remove',
                          );
                          remove(field.name);
                        }}
                        icon={<MinusCircleOutlined />}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                  {fields.length > 0 && i + 1 < fields.length && (
                    <Divider style={{ marginTop: 60, marginBottom: 60 }} />
                  )}
                </div>
              ))}
              <Form.Item>
                <Button
                  size="middle"
                  onClick={() => {
                    analytics.track(
                      'Work Experience Form - Clicked Add Another',
                    );
                    add();
                  }}
                  icon={<PlusCircleOutlined />}
                >
                  Add another role
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Button
          block
          type="primary"
          onClick={() =>
            analytics.track('Work Experience Form - Clicked Submit')
          }
          loading={loading}
          htmlType="submit"
          style={{ marginTop: 16 }}
        >
          Submit
        </Button>
        <Button
          block
          type="link"
          style={{ marginTop: 16, marginBottom: 32 }}
          onClick={() => {
            analytics.track('Clicked Do This Later');
            analytics.track('Work Experience Form Completed');
            onSuccess();
          }}
        >
          I don't have experience
        </Button>
      </Form>
    </div>
  );
};

export default WorkExperienceForm;
