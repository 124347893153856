import baseApi from './baseApi';

const getAllAvailable = () => {
  return baseApi.get('/shifts/available/');
};

const getUpcoming = (params) => {
  return baseApi.get('/shifts/upcoming/', { params });
};

export default { getAllAvailable, getUpcoming };
