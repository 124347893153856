import React from 'react';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import logoWithName from 'assets/images/staffie-logos/logo-with-name-black.svg';

const { Header, Content } = Layout;

const HEADER_HEIGHT_IN_PX = 80;
const CONTENT_WIDTH = 520;

const PublicLayout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Staffie - Work F&amp;B shifts on your own schedule.</title>
      </Helmet>
      <Layout
        style={{
          padding: '24px 16px',
          maxWidth: CONTENT_WIDTH,
          margin: 'auto',
        }}
      >
        <Header style={{ height: HEADER_HEIGHT_IN_PX, padding: 0 }}>
          <img
            src={logoWithName}
            alt="staffie-logo-with-name"
            style={{ height: 30, width: 'auto' }}
          />
        </Header>
        <Content
          style={{
            height: '100%',
          }}
        >
          {children}
        </Content>
      </Layout>
    </>
  );
};

export default PublicLayout;
