import React, { useEffect, useState, useContext } from 'react';
import { Input, Modal, Button, Form, message } from 'antd';

import prosApi from 'network/prosApi';
import ProContext from 'contexts/ProContext';
import Typography from 'components/Typography';
import analytics from 'utils/analytics';

const VerificationModal = ({ onSuccess, onCancel }) => {
  const { profile, setProfile } = useContext(ProContext);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    analytics.track('Viewed Pro Verification Form');
  }, []);

  const verifyPro = async (values) => {
    setSubmitting(true);
    analytics.track('Submitted Pro Verification Form');
    try {
      const response = await prosApi.update(profile.id, values);
      analytics.track('Pro Verification Successful');
      setProfile(response.data);
      message.success('Verification Successful. Welcome to Staffie!');
      onSuccess();
    } catch (error) {
      analytics.track('Pro Verification Failed');
      message.error('Sorry, something went wrong. Please re-fill the form');
      form.resetFields();
    }
    setSubmitting(false);
  };

  return (
    <Modal
      centered
      visible
      onCancel={onCancel}
      bodyStyle={{ maxHeight: 500, overflow: 'auto' }}
      title={
        <Typography.Body1SharpGrotesk style={{ fontWeight: 'bold' }}>
          Verification &amp; bank details
        </Typography.Body1SharpGrotesk>
      }
      footer={
        <Button
          block
          htmlType="submit"
          loading={submitting}
          type="primary"
          size="large"
          onClick={() => {
            analytics.track('Clicked Verify Pro Verification Form');
            form.submit();
          }}
        >
          Verify
        </Button>
      }
    >
      <Typography.Body2 style={{ marginBottom: 32 }}>
        Just one last step here! To verify that you meet Singapore’s work eligibility requirements, and for us to pay
        you your wages on time—we just need some simple information from you.
      </Typography.Body2>

      <Form hideRequiredMark size="large" layout="vertical" initialValues={profile} form={form} onFinish={verifyPro}>
        <Form.Item
          name="ic_number"
          label="Last 4 digits of NRIC"
          extra="i.e. ‘024R’ if your NRIC is S324024R"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input placeholder="024R" />
        </Form.Item>

        <Form.Item
          name="date_of_birth"
          label="Date of birth"
          extra="Please use the format: YYYY-MM-DD"
          rules={[
            {
              required: true,
              message: 'Check that the format is correct',
              pattern: new RegExp('^(19|20)\\d\\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$'),
            },
          ]}
        >
          <Input placeholder="1994-05-30" />
        </Form.Item>

        <Form.Item name="bank_name" label="Bank name" rules={[{ required: true, message: 'This field is required' }]}>
          <Input placeholder="DBS, OCBC..." />
        </Form.Item>

        <Form.Item
          name="bank_account_number"
          label="Bank account number"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input plcaeholder="588727233" />
        </Form.Item>

        <Form.Item
          name="bank_account_name"
          label="Bank account holder's name"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input placeholder="Hersha Patel" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default VerificationModal;
