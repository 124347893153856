import React, { useState } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import lodash from 'lodash';
import colors from '../../colors';
import ShiftCard from './ShiftCard';

const ShiftsScheduleForm = ({
  shifts,
  pastShifts,
  onChange,
  onBack,
  onSubmit,
}) => {
  const [daysVisible, setDaysVisible] = useState(10);

  const days = [...Array(daysVisible).keys()].map((n) => {
    return moment().startOf('day').add(n, 'days');
  });

  const getTemplateShift = (day) => {
    // Get the template shift with the following order of priority:
    // 1. Past shifts on the same day of week
    // 2. Latest shift in the past
    // 3. Latest shift that has been created in the form state
    // 4. Default template

    const countShiftsInDay = shifts.filter((s) =>
      moment(s.start_time).isSame(day, 'day'),
    ).length;
    const pastShiftForWeekday = lodash.orderBy(
      pastShifts.filter(
        (shift) => moment(shift.start_time).weekday() === day.weekday(),
      ),
      'start_time',
      ['desc'],
    )[countShiftsInDay];
    if (pastShiftForWeekday) {
      return pastShiftForWeekday;
    }

    const pastShift = pastShifts[0];
    if (pastShift) {
      return pastShift;
    }

    const latestShift = lodash.last(lodash.orderBy(shifts, 'start_time'));
    if (latestShift) {
      return latestShift;
    }

    const startTime = moment(day);
    startTime.hour(9);
    startTime.minute(0);

    return {
      uuid: uuidv4(),
      staff_required: 1,
      start_time: startTime.toISOString(),
      hours: 8,
      hourly_wage: 10,
    };
  };

  const handleAddShift = (day) => {
    // Create the new shift based on a template shift
    const templateShift = getTemplateShift(day);
    console.log('templateShift', templateShift);
    const startTime = moment(day);
    const sourceStartTime = moment(templateShift.start_time);
    startTime.hours(sourceStartTime.hours());
    startTime.minutes(sourceStartTime.minutes());

    const newShift = {
      id: undefined,
      uuid: uuidv4(),
      start_time: startTime.toISOString(),
      hours: templateShift.hours,
      staff_required: templateShift.staff_required,
      hourly_wage: templateShift.hourly_wage,
      break_hours: templateShift.break_hours,
    };
    onChange([...shifts, newShift]);
  };

  const handleUpdateShift = (updatedShift) => {
    onChange(
      shifts.map((shift) => {
        if (shift.uuid !== updatedShift.uuid) {
          return shift;
        }

        return updatedShift;
      }),
    );
  };

  const handleDeleteShift = (uuid) => {
    onChange(shifts.filter((s) => s.uuid !== uuid));
  };

  return (
    <div>
      {days.map((day) => {
        const existingShiftsInDay = pastShifts.filter((s) =>
          day.isSame(moment(s.start_time), 'day'),
        );
        const shiftsInDay = shifts.filter((shift) => {
          return day.isSame(moment(shift.start_time), 'day');
        });

        return (
          <div
            key={day}
            style={{
              padding: 16,
              borderBottom: `1px solid ${colors.lightgrey}`,
              display: 'flex',
            }}
          >
            <div style={{ minWidth: 120 }}>{day.format('ddd, DD MMM')}</div>
            <div>
              <div>
                <div>
                  {existingShiftsInDay.map((shift) => (
                    <div key={shift.id} style={{ marginBottom: 8 }}>
                      <ShiftCard shift={shift} />
                    </div>
                  ))}
                  {shiftsInDay.map((shift) => (
                    <div key={shift.uuid} style={{ marginBottom: 8 }}>
                      <ShiftCard
                        shift={shift}
                        onChange={(updatedShift) => {
                          handleUpdateShift(updatedShift);
                        }}
                        onDelete={() => {
                          handleDeleteShift(shift.uuid);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <Button type="link" onClick={() => handleAddShift(day)}>
                + Add shift
              </Button>
            </div>
          </div>
        );
      })}
      <Button
        block
        type="secondary"
        style={{ marginBottom: 16 }}
        onClick={() => setDaysVisible(daysVisible + 7)}
      >
        Show more
      </Button>
      <Button type="secondary" onClick={onBack}>
        Back
      </Button>
      <Button type="primary" onClick={onSubmit}>
        Next
      </Button>
    </div>
  );
};

export default ShiftsScheduleForm;
