import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import bookingsApi from 'network/bookingsApi';

import analytics from 'utils/analytics';
import ProContext from 'contexts/ProContext';
import Typography from 'components/Typography';
import AppBanner from '../../components/AppBanner';

const CANCELLED_STATUS_KEY_WORD = 'cancelled';
const DEFAULT_BOOKING_STATUS = 'Pending';

const BookingsPage = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const { profile } = useContext(ProContext);

  useEffect(() => {
    const fetchData = async () => {
      if (profile) {
        setLoading(true);
        const today = moment().startOf('day').toISOString();
        const response = await bookingsApi.get({
          shift__start_time__gte: today,
        });

        setUpcomingBookings(response.data.results);
        setLoading(false);
      }
    };

    analytics.page('Bookings');
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  return (
    <div>
      <section style={{ marginBottom: 48 }}>
        <Typography.H3>My booked shifts</Typography.H3>
        <Typography.Body1>
          Check your schedule of booked shifts and manage them.
        </Typography.Body1>
      </section>
      <AppBanner
        title="Download the app"
        body="View all your shifts on the Staffie app for a better experience."
        buttonText="Get the app"
      />
    </div>
  );
};

export default BookingsPage;
