import { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import localStorageKeys from 'constants/localStorageKeys';
import routes from 'routes';
import AppLayout from 'layouts/AppLayout';
import PublicLayout from 'layouts/PublicLayout';
import prosApi from 'network/prosApi';

import ProContext from 'contexts/ProContext';
import Loader from 'components/Loader';

import ShiftsView from 'pages/ShiftsView';
import BookingsPage from 'pages/BookingsPage';
import SignupPage from 'pages/SignupPage';
import LoginPage from 'pages/LoginPage';

import analytics from 'utils/analytics';
import AdminPage from './pages/AdminPage';

const authPages = [
  {
    exact: false,
    path: routes.shiftsHome,
    component: ShiftsView,
    layout: AppLayout,
  },
  {
    exact: true,
    path: routes.bookings,
    component: BookingsPage,
    layout: AppLayout,
  },
  { exact: true, path: routes.admin, component: AdminPage, layout: AppLayout },
];
const publicPages = [
  {
    exact: true,
    path: routes.signup,
    component: SignupPage,
    layout: PublicLayout,
  },
  {
    exact: true,
    path: routes.login,
    component: LoginPage,
    layout: PublicLayout,
  },
];

const allPages = [...authPages, ...publicPages];

const App = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState();

  useEffect(() => {
    const parseQueryParams = () => {
      // Store utm content if present for ad attribution in signup
      const urlParams = queryString.parse(location.search);
      if (urlParams?.utm_content) {
        window.localStorage.setItem('utm_content', urlParams.utm_content);
      }
      if (urlParams?.referral) {
        window.localStorage.setItem(
          'referral',
          `${urlParams?.referral.trim()}`,
        );
      }
    };

    const fetchProfile = async () => {
      if (!localStorage.getItem(localStorageKeys.AUTH_TOKEN)) {
        setLoading(false);
        return;
      }

      try {
        const proResponse = await prosApi.getMyself();
        setProfile(proResponse.data);
        analytics.identifyPro(proResponse.data);
      } catch (error) {
        // TODO: Some log that login failed
      }
      setLoading(false);
    };

    fetchProfile();
    parseQueryParams();
  }, []);

  if (loading) return <Loader />;

  return (
    <ProContext.Provider
      value={{
        profile,
        setProfile: (updatedProfile) => setProfile(updatedProfile),
      }}
    >
      <Switch>
        {allPages.map(
          ({ exact, path, component: Component, layout: Layout }) => (
            <Route
              key={path}
              exact={exact}
              path={path}
              render={() => (
                <Layout>
                  <Component />
                </Layout>
              )}
            />
          ),
        )}
        <Redirect to={routes.shiftsHome} />
      </Switch>
    </ProContext.Provider>
  );
};

export default App;
