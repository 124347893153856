import React from 'react';
import { Button, Form, InputNumber, Modal, TimePicker } from 'antd';
import moment from 'moment';
import { getShiftDatetimeDurationString } from '../../utils/timeUtils';

const ShiftEditModal = ({ shift, visible, onChange, onDelete, onClose }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      destroyOnClose
      onOk={async () => {
        const values = await form.validateFields();

        // We do this to make sure Antd TimePicker doesn't change the day in question
        const startTime = moment(shift.start_time);
        startTime.hours(values.startTime.hours());
        startTime.minutes(values.startTime.minutes());

        const endTime = moment(shift.start_time);
        endTime.hours(values.endTime.hours());
        endTime.minutes(values.endTime.minutes());
        if (endTime < startTime) {
          endTime.add(1, 'day');
        }
        const minutes = endTime.diff(moment(startTime), 'minutes');
        const hours = minutes / 60;
        console.log('hours', hours);

        onChange({
          ...shift,
          start_time: startTime.toISOString(),
          break_hours: values.breakHours,
          staff_required: values.staffRequired,
          hourly_wage: values.hourlyWage,
          hours,
        });
      }}
      onCancel={onClose}
    >
      <div>{getShiftDatetimeDurationString(shift.start_time, shift.hours)}</div>
      <Form
        form={form}
        initialValues={{
          startTime: moment(shift.start_time),
          endTime: moment(shift.start_time).add(shift.hours, 'hours'),
          breakHours: shift.break_hours,
          staffRequired: shift.staff_required,
          hourlyWage: shift.hourly_wage,
        }}
      >
        <Form.Item name="startTime" label="Start time">
          <TimePicker format="HH:mm" minuteStep={15} allowClear={false} />
        </Form.Item>
        <Form.Item name="endTime" label="End time">
          <TimePicker format="HH:mm" minuteStep={15} allowClear={false} />
        </Form.Item>
        <Form.Item name="staffRequired" label="Staff required">
          <InputNumber />
        </Form.Item>
        <Form.Item name="hourlyWage" label="Hourly wage (S$/hour)">
          <InputNumber />
        </Form.Item>
        <Form.Item name="breakHours" label="Break hours">
          <InputNumber />
        </Form.Item>
      </Form>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="link" onClick={onDelete}>
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default ShiftEditModal;
