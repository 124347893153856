export const normalizePhoneNumber = (value) => {
  return value?.replace(/ /g, '').replace(/^\+?65/, '');
};

export const convertToSlug = (text) => {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
};
